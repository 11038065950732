import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import Repository from './SessionCard';
import useBoxContext from './SessionsContext';
import { kanbanIcon } from '../../Settings/utilsKanban';

const Col = styled.div`
  height: calc(100vh - 278px);
  position: relative;
  min-width: 250px;
  margin: 5px;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid rgba(235, 237, 242, 0.5);
`;

const Title = styled.div`
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: center;
  top: 0;
  background-color: #2b2b2b;
  box-shadow: 0px 9px 16px -6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 96%;
  text-align: left;
  color: #fff;
  height: 42px;
  #icon {
    padding: 0 20px;
  }
  #label {
    letter-spacing: 0.02em;
    font-weight: 500;
  }
`;
const CardContainer = styled.div`
  position: absolute;
  top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SessionKanbanColumns = ({ column: { _id, label, icon, color } }) => {
  const { updateSession, sessions } = useBoxContext();
  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop: async item => {
      await updateSession(item.id, { status: _id });
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div>
      <Col ref={drop}>
        <Title>
          <div id="icon">
            {kanbanIcon.filter(ic => ic.label === icon)[0]?.value}
          </div>
          <div id="label">{label}</div>
        </Title>
        <CardContainer>
          {sessions
            .filter(session => session.status._id === _id || session.status === _id || session.status.label === label)
            .map(session => (
              <Repository color={color} session={session} key={session._id} />
            ))}
        </CardContainer>
      </Col>
    </div>
  );
};

SessionKanbanColumns.propTypes = {
  column: PropTypes.shape({
    _id: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
  }).isRequired,
};

export default SessionKanbanColumns;
