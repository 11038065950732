import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  PageHeader,
  Row,
  Space
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalSendMails from '../Convocations/modalSendMails';
import ListFiles from './ListFiles';
import useAuthContext from '../../../contexts/AuthContext';
import EmailHistoryTable from './EmailHistoryTable';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Panel } = Collapse;

const Emails = ({
  session,
  trainees,
  customers,
  contacts,
  emails,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [file, setFile] = useState([]);
  const [isDownloading, setIsDownloading] = useState({});
  const [emailPurpose, setEmailPurpose] = useState('');
  const [sendType, setSendType] = useState('one');
  const [emailModel, setEmailModel] = useState('');
  const [globalEmail, setGlobalEmail] = useState(false);
  const [isSelectedDocument, setIsSelectedDocument] = useState(false);
  const [listFiles, setListFiles] = useState({});
  const { notification } = ErrorStatusCode();

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const showModal = (purpose, id, file, type) => {
    switch (purpose) {
      case 'trainees':
        setEmailModel('trainees_emails_model');
        setUsers(
          trainees.map((el) => ({
            ...el,
            ref: 'Trainees'
          }))
        );
        break;
      case 'contributors':
        setEmailModel('contributors_email_model');
        setUsers(
          session.contributors.map(({ contributor }) => ({
            ...contributor,
            id: contributor._id,
            ref: 'Contributor'
          }))
        );
        break;
      case 'Trainees':
        setEmailModel('trainees_emails_model');
        setUsers(
          trainees
            .filter((el) => el._id.toString() === id.toString())
            .map((el) => ({
              ...el,
              ref: 'Trainees'
            }))
        );
        break;
      case 'Contributor':
        setEmailModel('contributors_email_model');
        setUsers(
          session.contributors.map(({ contributor }) => ({
            ...contributor,
            id: contributor._id,
            ref: 'Contributor'
          }))
        );
        break;
      case 'Company':
        setEmailModel('customers_email_model');
        setUsers(
          contacts
            .filter(
              (el) =>
                el.my_company._id.toString() === id.toString() &&
                el.my_company.representative
            )
            .map((el) => ({
              id: el._id,
              ...el,
              ref: 'Contact'
            }))
        );
        break;
      default:
        break;
    }
    if (file !== undefined && Object.keys(file).length) {
      setFile([
        {
          id: file._id,
          fileName: file.filename
        }
      ]);
    }
    if (type) {
      setGlobalEmail(type);
    }
    setIsModalVisible(!isModalVisible);
  };

  const typeList = [
    'company_convention',
    'private_convention',
    'quotations',
    'invoices',
    'subcontracting',
    'convocation',
    'training_action_completion',
    'attendance_certificate',
    'programs',
    'credit_notes'
  ];

  const processFiles = (target, id, list) => {
    if (target) {
      target.forEach((item) => {
        if (target === trainees) {
          const files = [];
          typeList.forEach((type) => {
            session.files.forEach((el) => {
              if (el.user && el.user._id === item?._id && el.type === type) {
                files.push(el);
              } else if (el.type === 'programs') {
                files.push(el);
              }
            });
          });
          files.sort((a, b) => new Date(b.date) - new Date(a.date));
          list[item?._id] = files
            .filter((el) => el)
            .map((file) => ({
              ...file,
              user: file.user ? file.user : { _id: item?._id }
            }));
        } else {
          const files = [];
          typeList.forEach((type) => {
            session.files.forEach((el) => {
              if (
                el.user &&
                el.user._id === item[id]?._id &&
                el.type === type
              ) {
                files.push(el);
              } else if (el.type === 'programs') {
                files.push(el);
              }
            });
          });
          files.sort((a, b) => new Date(b.date) - new Date(a.date));
          list[item[id]?._id] = files
            .filter((el) => el)
            .map((file) => ({
              ...file,
              user: file.user ? file.user : { _id: item[id]?._id }
            }));
        }
      });

      return list;
    }
  };

  useEffect(() => {
    const list = {};
    processFiles(session.contributors, 'contributor', list);
    processFiles(trainees, 'trainee', list);
    processFiles(customers, 'customer', list);
    setListFiles(list);
  }, [session, trainees, customers]);

  return (
    <>
      <Divider orientation="left">
        <span>{t('sessions.emails.title')}</span>
      </Divider>
      <Card>
        <ModalSendMails
          session={session}
          file={file}
          sendType={sendType}
          globalEmail={globalEmail}
          setGlobalEmail={setGlobalEmail}
          emailModel={emailModel}
          emailPurpose={emailPurpose}
          refresh={forceRefresh}
          setRefresh={setForceRefresh}
          users={users}
          files={session.files}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          isSelectedDocument={isSelectedDocument}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setSendType('multi');
              showModal('trainees', '', {}, true);
              setIsSelectedDocument(true);
            }}
            style={{ marginRight: 6 }}
          >
            {t('sessions.emails.trainees')}
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setSendType('multi');
              showModal('contributors', '', {}, true);
              setIsSelectedDocument(true);
            }}
          >
            {t('sessions.emails.contributors')}
          </Button>
        </Space>
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.emails.send_file')}</span>
      </Divider>
      <Card className="session-email-files-card">
        <Row>
          <Col span={8}>
            <PageHeader title={t('sessions.emails.contributors')} />
            <Collapse accordion ghost>
              {session.contributors &&
                session.contributors.map(({ contributor }) => (
                  <Panel
                    key={contributor?._id}
                    header={`${contributor?.last_name} ${contributor?.first_name}`}
                  >
                    {listFiles[contributor?._id] &&
                    listFiles[contributor?._id].length ? (
                      <ListFiles
                        showModal={showModal}
                        setSendType={setSendType}
                        setIsSelectedDocument={setIsSelectedDocument}
                        downloadFile={downloadFile}
                        files={listFiles[contributor?._id]}
                        reference="Contributor"
                      />
                    ) : (
                      <span>{t('sessions.emails.no_document')}</span>
                    )}
                  </Panel>
                ))}
            </Collapse>
          </Col>
          <Col span={8}>
            <PageHeader title={t('sessions.emails.trainees')} />
            <Collapse accordion ghost>
              {trainees &&
                trainees.map((trainee) => (
                  <Panel
                    key={trainee._id}
                    header={`${trainee.last_name} ${trainee.first_name}`}
                  >
                    {listFiles[trainee._id] && listFiles[trainee._id].length ? (
                      <ListFiles
                        showModal={showModal}
                        setSendType={setSendType}
                        setIsSelectedDocument={setIsSelectedDocument}
                        downloadFile={downloadFile}
                        files={listFiles[trainee._id]}
                        reference="Trainees"
                      />
                    ) : (
                      <span>{t('sessions.emails.no_document')}</span>
                    )}
                  </Panel>
                ))}
            </Collapse>
          </Col>
          <Col span={8}>
            <PageHeader title={t('sessions.emails.customers')} />
            <Collapse accordion ghost>
              {customers &&
                customers
                  .filter((el) => el.ref === 'Company')
                  .map(({ customer }) => (
                    <Panel key={customer?._id} header={customer?.name}>
                      {listFiles[customer?._id] &&
                      listFiles[customer?._id].length ? (
                        <ListFiles
                          showModal={showModal}
                          setSendType={setSendType}
                          setIsSelectedDocument={setIsSelectedDocument}
                          downloadFile={downloadFile}
                          files={listFiles[customer._id]}
                          reference="Company"
                        />
                      ) : (
                        <span>{t('sessions.emails.no_document')}</span>
                      )}
                    </Panel>
                  ))}
            </Collapse>
          </Col>
        </Row>
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.emails.history')}</span>
      </Divider>
      <EmailHistoryTable
        forceRefresh={forceRefresh}
        setForceRefresh={setForceRefresh}
        emails={emails.filter((el) => el.session)}
        session={session}
      />
    </>
  );
};

export default Emails;

Emails.propTypes = {
  session: PropTypes.shape({
    contributors: PropTypes.shape({ _id: PropTypes.string }),
    files: PropTypes.shape({
      user: PropTypes.shape({ _id: PropTypes.string }),
      type: PropTypes.string
    })
  }).isRequired,
  trainees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  emails: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired
};
