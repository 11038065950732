import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Input, Select, Button, Alert, Col, Row } from 'antd';
import { PlusOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import NewContributorDrawer from '../../Donnees/Contributors/NewContributorDrawer';
import useHandleResize from '../../../utils/HandleResize';
import checkSubscriptionPermissions from '../../../utils/checkSubscriptionPermissions';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const useFields = (status, setStatus) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { notification } = ErrorStatusCode();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { dispatchAPI, user } = useAuthContext();
  const [contributors, setContributors] = useState([]);
  const [enums, setEnums] = useState([]);
  const [
    visibilityContributorDrawer,
    setVisibilityContributorDrawer
  ] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const [minSubscription, setMinSubscription] = useState({});
  const history = useHistory();
  const loc = useLocation();
  const splitURL = loc.pathname.split('/');
  const idSession = splitURL[3];
  const idStatus = splitURL[5];

  const { width } = useHandleResize();

  const drawerVisibility = () => {
    setVisibilityContributorDrawer(!visibilityContributorDrawer);
  };

  const getSubscription = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/subscriptions?title=AISANCE`
      });
      setMinSubscription(data[0]);
    } catch (e) {
      console.log(e.response);
    }
  };

  const [idContributors, setIdContributors] = useState([]);
  const fetchSessionId = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${idSession}`
      });
      setIdContributors(data.contributors.map((c) => c.contributor));
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getContributor = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contributors`
      });
      setResourceData(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await getSubscription();
      await getContributor();
      await fetchSessionId();
    })();
  }, []);

  const { Option } = Select;

  const fields = [
    {
      name: ['contributors'],
      rules: [{ required: true }],
      input: (
        <Input.Group compact>
          <NewContributorDrawer
            isVisible={visibilityContributorDrawer}
            setVisibilityContributorDrawer={drawerVisibility}
            refresh={refresh}
            setRefresh={setRefresh}
          />
          <Form.Item name={['contributors']}>
            <Select
              style={{ width: 370 }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={isFieldsLoading}
            >
              {contributors.map((contributor) => (
                <Option key={contributor._id} value={contributor._id}>
                  {`${contributor.last_name} ${contributor.first_name}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={['contributors']}>
            <Button
              type="primary"
              onClick={drawerVisibility}
              style={{
                float: width > 1700 ? 'right' : 'none',
                marginLeft: width > 1700 ? 10 : 0
              }}
            >
              {user &&
                user.organization &&
                user.role === 'users:SUPER-USER' &&
                !checkSubscriptionPermissions(
                  'contributors',
                  resourceData.length,
                  user.organization.current_subscription || minSubscription
                )}
              <span style={{ fontSize: '15px' }}>{t('buttons.create')}</span>
              <PlusOutlined />
            </Button>
            <Row style={{ justifyContent: 'flex-end' }}>
              <Col xxl={6} xl={10} lg={18} md={20} sm={22} xs={24}>
                {user &&
                  user.organization &&
                  user.role === 'users:SUPER-USER' &&
                  !checkSubscriptionPermissions(
                    'contributors',
                    resourceData.length,
                    user.organization.current_subscription || minSubscription
                  ) && (
                    <Alert
                      showIcon
                      message={
                        <span>
                          {user.organization.current_subscription
                            ? t(`buttons.infos.users`)
                            : t('profile.minSubscription')}
                          <Button
                            onClick={() => {
                              history.push('/profile');
                              localStorage.setItem('subscriptionIndex', '2');
                            }}
                            type="link"
                            icon={<ArrowRightOutlined />}
                          />
                        </span>
                      }
                      type="warning"
                    />
                  )}
              </Col>
            </Row>
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['status'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(value) => setStatus(value)}
          loading={isFieldsLoading}
        >
          {(enums.status || []).map((title) => (
            <Option key={title} value={title}>
              {t(`contributors.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['rate'],
      input: <Input type="number" suffix="€" />,
      rules: [{ required: status === 'EXTERNAL' && true }]
    },
    {
      name: ['duration'],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={isFieldsLoading}
        >
          {(enums.duration || []).map((title) => (
            <Option key={title} value={title}>
              {t(`contributors.form.${title}`)}
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: status === 'EXTERNAL' && true }]
    },
    {
      name: ['tva'],
      input: <Input type="number" suffix="%" />,
      rules: [{ required: status === 'EXTERNAL' && true }]
    }
  ];

  const getNewContributors = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contributors' });
      const dataFinal = data.filter((contributor) => {
        return !(
          idContributors.length && idContributors.includes(contributor._id)
        );
      });
      idStatus === 'edit' ? setContributors(data) : setContributors(dataFinal);
      return contributors;
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contributors/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await fetchSessionId();
    await getEnums();
    setIsFieldsLoading(false);
  }, [refresh]);

  useEffect(() => {
    (async () => {
      await getNewContributors();
    })();
  }, [idContributors]);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return {
    fields,
    isFieldsLoading
  };
};

export default useFields;
