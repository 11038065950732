import React, { useEffect, useState } from 'react';
import { Badge, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import columns from './columns';

const RecapQualityNotes = ({ type, rowsData }) => {
  const { t } = useTranslation();

  const expandedRowSessionRender = customers => {
    const column = [
      {
        title: 'Name',
        width: '11.7%',
        ellipsis: {
          showTitle: false,
        },
        render: customer => (
          <Tooltip
            placement="topLeft"
            title={
              customer.last_name
                ? `${customer.last_name} ${customer.first_name}`
                : customer.name
            }>
            {customer.last_name
              ? `${customer.last_name} ${customer.first_name}`
              : customer.name}
          </Tooltip>
        ),
      },
      {
        title: t('quality.columns.post_training_j'),
        width: '32.3%',
        render: customer =>
          customer.notes.find(el => el.type === 'post_training_j').note
            ? customer.notes
                .find(el => el.type === 'post_training_j')
                .note.toFixed(2)
            : 'N/A',
        sorter: true,
      },
      {
        title: t('quality.columns.post_training_month'),
        width: '30.6%',
        render: customer =>
          customer.notes.find(el => el.type === 'post_training_month').note
            ? customer.notes
                .find(el => el.type === 'post_training_month')
                .note.toFixed(2)
            : 'N/A',
        sorter: true,
      },
      {
        title: t('quality.columns.post_training_month'),
        render: customer =>
          customer.notes.find(el => el.type === 'global').note
            ? customer.notes.find(el => el.type === 'global').note.toFixed(2)
            : 'N/A',
        sorter: true,
      },
    ];

    return (
      <Table
        bordered
        expandable={{
          expandedRowRender: record =>
            expandedRowCompanyRender(record.trainees_list),
          rowExpandable: record => record.trainees_list.length,
        }}
        showHeader={false}
        columns={column}
        dataSource={customers}
        pagination={false}
      />
    );
  };

  const expandedRowCompanyRender = traineesList => {
    const column = [
      {
        title: 'Name',
        width: '12%',
        render: trainee => trainee.name,
      },
      {
        title: t('quality.columns.post_training_j'),
        width: '33.6%',
        render: trainee =>
          trainee.notes.find(el => el.type === 'post_training_j').note
            ? trainee.notes
                .find(el => el.type === 'post_training_j')
                .note.toFixed(2)
            : 'N/A',
        sorter: true,
      },
      {
        title: t('quality.columns.post_training_month'),
        width: '31.9%',
        render: trainee =>
          trainee.notes.find(el => el.type === 'post_training_month').note
            ? trainee.notes
                .find(el => el.type === 'post_training_month')
                .note.toFixed(2)
            : 'N/A',
        sorter: true,
      },
      {
        title: t('quality.columns.global'),
        render: trainee =>
          trainee.notes.find(el => el.type === 'global').note
            ? trainee.notes.find(el => el.type === 'global').note.toFixed(2)
            : 'N/A',
        sorter: true,
      },
    ];
    return (
      <Table
        showHeader={false}
        columns={column}
        bordered
        dataSource={traineesList}
        pagination={false}
      />
    );
  };

  const expandedRowRender = sessions => {
    return (
      <Table
        expandable={{
          expandedRowRender: record =>
            expandedRowSessionRender(
              record.customers.map(el => ({ ...el, key: el._id }))
            ),
        }}
        showHeader={false}
        columns={columns(t, type, 'session')}
        dataSource={sessions}
        bordered
        pagination={false}
      />
    );
  };

  return (
    <>
      <Table
        className="components-table-demo-nested"
        columns={columns(t, type, 'year')}
        expandable={{
          expandedRowRender: record => expandedRowRender(record.sessions),
        }}
        bordered
        dataSource={rowsData}
        pagination={false}
      />
    </>
  );
};

export default RecapQualityNotes;
