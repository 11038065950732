import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext
} from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory, useLocation } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

export const SessionsContext = createContext({});

export const SessionsContextProvider = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { dispatchAPI } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [session, setSession] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const [searchTypeValue, setSearchTypeValue] = useState(
    params.get('searchType')
  );
  const [kanbanCols, setKanbanCols] = useState([]);
  const [dataRefresh, setDataRefresh] = useState(false);
  const [modules, setModules] = useState([]);
  const [fetching, setFetching] = useState(false);
  const { notification } = ErrorStatusCode();

  const searchResource = (value, searchType) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}&searchType=${searchType}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  useEffect(() => {
    setSearchValue(params.get('k'));
    setSearchTypeValue(params.get('searchType'));
  }, [params]);

  const fetchData = useCallback(
    async ({ projectionFields }) => {
      setFetching(!fetching);
      const searchUrl = searchValue
        ? `filter={"$text": {"$search":"${searchValue}", "$searchType":"${searchTypeValue}"}}&`
        : null;
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/sessions?${searchUrl ||
            ''}&populate=customers.customer,status&${
            projectionFields ? `fields=${projectionFields}` : ''
          }&sessionsTab=true`
        });
        setSessions(data);
        setFetching(false);
        if (!data.length) {
          notification({
            data: { status_code: 404, description: 'sessionsUnfound' }
          });
        }
      } catch (e) {
        setFetching(!fetching);
        if (e.response) {
          notification(e.response);
        }
      }
    },
    [searchValue, visible]
  );

  const delSession = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `sessions/${id}` });
      await fetchData();
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const updateSession = async (id, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/sessions/${id}`, body });
      await fetchData();
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const fetchKanbanColumns = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/kanbans?type=sessions'
      });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const getModules = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/module' });
      setModules(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const fetch = useCallback(async () => {
    await fetchKanbanColumns();
    await getModules();
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <DndProvider backend={HTML5Backend}>
      <SessionsContext.Provider
        value={{
          fetchData,
          delSession,
          updateSession,
          sessions,
          session,
          setSession,
          searchResource,
          searchValue,
          searchTypeValue,
          visible,
          setVisible,
          kanbanCols,
          fetchKanbanColumns,
          dataRefresh,
          setDataRefresh,
          modules,
          fetching
        }}
      >
        {children}
      </SessionsContext.Provider>
    </DndProvider>
  );
};

SessionsContextProvider.propTypes = {
  children: PropTypes.shape(PropTypes.any).isRequired
};

export default () => useContext(SessionsContext);
