import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import moment from 'moment';
import useAuthContext from '../../contexts/AuthContext';
import Marge from './components/Marge';
import CaHT from './components/CaHT';
import CaPoten from './components/CaPoten';
import LastDoc from './components/LastDoc';
import LastDue from './components/LastDue';
import Donut from './components/Donut';
import useErrorMessage from '../../utils/ErrorMessage';

const monthTab = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Decembre'
];

const indicatorsColors = {
  transformationRate: 'var(--textColor)',
  unpaidRate: 'var(--addColor)',
  incompleteFiles: 'var(--textColorA015)',
  claimsRate: 'var(--secondaryColor)'
};

const IndicatorWrapper = ({ colSpan, refresh }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState();

  const fetchDataSessions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions?organization=${user.organization._id}&fields=contributors,_id,files.type,files.status,files.user,files.amount,files.date`
      });
      return data;
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const fetchDataAllInvoices = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices?organization=${user.organization._id}`
      });
      return data;
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const fetchDataModules = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `module?organization=${user.organization._id}&fields=slots.date.sub_slots.contributors,slots.date.date,session`
      });
      return data;
    } catch (error) {
      if (error.response) message(error.response.status);
    }
  };

  const fetchDataExpenses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/charges?organization=${user.organization._id}`
      });
      return data;
    } catch (error) {
      if (error.response) {
        return message(error.response.status);
      }
      return null;
    }
  };

  const fetchDataCreditNotes = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/credit-notes?organization=${user.organization._id}`
      });
      return data;
    } catch (error) {
      if (error.response) {
        return message(error.response.status);
      }
      return null;
    }
  };

  const setData = async () => {
    setIsLoading(true);
    try {
      const promises = await Promise.all([
        fetchDataSessions(),
        fetchDataAllInvoices(),
        fetchDataModules(),
        fetchDataExpenses(),
        fetchDataCreditNotes()
      ]);
      const [
        dataSessions,
        dataAllInvoices,
        dataModules,
        dataExpenses,
        dataCreditNotes
      ] = promises;

      const dashboardValues = {
        statsData: [],
        totalM_1: 0,
        totalN_1: 0,
        totalM: 0,
        totalN: 0,
        totMonthN_1: 0,
        totN_1: 0,
        tot8j: 0,
        totj: 0,
        tot_8j: 0,
        totN: 0,
        tot30j: 0,
        totMonthN: 0,
        tot_30j: 0,
        tot_j: 0,
        totalDepa: 0,
        totalInterLastMonth: 0,
        totalInterLastYear: 0,
        totalInterThisMonth: 0,
        totalInterThisYear: 0,
        totalCreditNoteLastMonth: 0,
        totalCreditNoteLastYear: 0,
        totalCreditNoteThisMonth: 0,
        totalCreditNoteThisYear: 0,
        totalLastMonth: 0,
        totalLastYear: 0,
        totalRegle: {
          total1: 0,
          total8: 0,
          total30: 0,
          total_1: 0,
          total_8: 0,
          total_30: 0
        },
        totalThisMonth: 0,
        totalThisYear: 0,
        totalVenir: 0
      };

      const listInvoices = dataAllInvoices.filter((el) => el.session);
      listInvoices.forEach((inv) => {
        if (moment(inv.emission_date).year() === moment().year()) {
          dashboardValues.totN += inv.total_ht;
          if (moment(inv.emission_date).month() === moment().month()) {
            dashboardValues.totMonthN += inv.total_ht;
          }
        } else if (moment(inv.emission_date).year() === moment().year() - 1) {
          dashboardValues.totN_1 += inv.total_ht;
          if (moment(inv.emission_date).month() === moment().month()) {
            dashboardValues.totMonthN_1 += inv.total_ht;
          }
          dashboardValues.totN_1 += inv.total_ht;
        }
        if (
          moment(inv.emission_date).year() === moment().year() &&
          inv.status !== 'PAID'
        ) {
          const nbDays = moment(inv.deadline_date).diff(moment(), 'days');
          let realT = inv.total_ht;
          if (inv.item?.length) {
            const TVAaverage =
              (inv.items || [])
                .map((el) => el.tva || 0)
                .reduce((a, b) => a + b) / inv?.items?.length || 1;
            realT =
              inv.total_ht - (inv.total_paid || 0) * (1 - TVAaverage / 100);
          }
          if (nbDays < -30) {
            dashboardValues.tot_j += realT;
          } else if (nbDays < -8) {
            dashboardValues.tot_30j += realT;
          } else if (nbDays < 0) {
            dashboardValues.tot_8j += realT;
          } else if (nbDays < 9) {
            dashboardValues.tot8j += realT;
          } else if (nbDays < 30) {
            dashboardValues.tot30j += realT;
          } else {
            dashboardValues.totj += realT;
          }
        }
      });

      const dataDonut = [
        {
          type: '+30j ',
          value: dashboardValues.totj
        },
        {
          type: '9-30j ',
          value: dashboardValues.tot30j
        },
        {
          type: '0-8j',
          value: dashboardValues.tot8j
        },
        {
          type: '1-8j',
          value: dashboardValues.tot_8j
        },
        {
          type: '9-30j',
          value: dashboardValues.tot_30j
        },
        {
          type: '+30j',
          value: dashboardValues.tot_j
        }
      ];

      dashboardValues.totalRegle = {
        total_1: dashboardValues.tot_8j,
        total_8: dashboardValues.tot_30j,
        total_30: dashboardValues.tot_j,
        total1: dashboardValues.tot8j,
        total8: dashboardValues.tot30j,
        total30: dashboardValues.totj
      };
      dashboardValues.totalThisMonth = dashboardValues.totMonthN;
      dashboardValues.totalLastMonth = dashboardValues.totMonthN_1;
      dashboardValues.totalLastYear = dashboardValues.totN_1;
      dashboardValues.totalThisYear = dashboardValues.totN;
      dashboardValues.statsData = dataDonut;
      dashboardValues.totalVenir =
        dashboardValues.totj + dashboardValues.tot8j + dashboardValues.tot30j;
      dashboardValues.totalDepa =
        dashboardValues.tot_30j +
        dashboardValues.tot_8j +
        dashboardValues.tot_j;

      const contri = dataSessions.map((session) => {
        const check = dataAllInvoices.filter(
          (invoice) => invoice.sessions === session._id
        ).length;
        if (check) {
          return (
            session.contributors &&
            session.contributors.map((ct) => {
              let dates = [];
              const modulesList = dataModules.filter(
                (module) => module.session === session._id
              );
              modulesList.forEach((mod) => {
                dates = [
                  ...dates,
                  ...mod.slots.date.filter((dt) => {
                    dt.sub_slots.find((ss) => {
                      ss.contributors.includes(ct.contributors?._id);
                    });
                  })
                ];
              });
              return {
                ...ct,
                price: ct.rate ?? ct.contributors?.rate ?? 0,
                tva: ct.tva ?? ct.contributors?.tva ?? 0,
                duration:
                  ct.duration || ct.contributors?.duration || 'FLATE_RATE',
                dates,
                session: session._id,
                year: moment(modulesList[0]?.slots.date[0].date).format('YYYY')
              };
            })
          );
        }
      });

      contri
        .map((cont) => (cont !== undefined ? cont : []))
        .forEach((el) => {
          el.forEach((cont) => {
            if (cont.year === `${moment().year()}`) {
              if (cont.duration === 'FLATE_RATE') {
                dashboardValues.totalN +=
                  cont.price * (1 + parseInt(cont.tva, 10) / 100);
                dashboardValues.totalM +=
                  cont.price * (1 + parseInt(cont.tva, 10) / 100);
              } else if (cont.duration === 'DAY') {
                dashboardValues.totalM =
                  cont.price *
                  (1 + parseInt(cont.tva, 10) / 100) *
                  cont.dates.filter((dt) => {
                    return (
                      moment(dt.date).format('MM YYYY') ===
                      moment().format('MM YYYY')
                    );
                  }).length;
                dashboardValues.totalN +=
                  cont.price *
                  cont.dates.length *
                  (1 + parseInt(cont.tva, 10) / 100);
              }
            } else if (cont.year === `${moment().year - 1}`) {
              if (cont.duration === 'FLATE_rate') {
                dashboardValues.totalN_1 +=
                  cont.price * (1 + parseInt(cont.tva, 10) / 100);
                const listDates = cont.dates.filter(
                  (dt) => moment(dt.date).month() === moment().month()
                );
                if (listDates.length) {
                  const price = cont.price / cont.dates.length;
                  dashboardValues.totalM_1 +=
                    price *
                    listDates.length *
                    (1 + parseInt(cont.tva, 10) / 100);
                }
              } else if (cont.duration === 'DAY') {
                cont.dates.forEach((dt) => {
                  if (moment(dt.date).month() === moment().month()) {
                    dashboardValues.totalM_1 +=
                      cont.price * (1 + parseInt(cont.tva, 10) / 100);
                  }
                });
                dashboardValues.totalN_1 +=
                  cont.price *
                  cont.dates.length *
                  (1 + parseInt(cont.tva, 10) / 100);
              }
            }
          });
        });

      dataCreditNotes.forEach((credit_note) => {
        const check = dataAllInvoices.filter(
          (invoice) => invoice.session === credit_note.session
        ).length;
        if (check) {
          if (
            moment(credit_note.emission_date).format('YYYY') ===
            moment().format('YYYY')
          ) {
            dashboardValues.totalCreditNoteThisYear += credit_note.total_ttc;
            if (
              moment(credit_note.emission_date).format('MM') ===
              moment().format('MM')
            ) {
              dashboardValues.totalCreditNoteThisMonth += credit_note.total_ttc;
            }
          } else if (
            moment(credit_note.emission_date).year() ===
            moment().year() - 1
          ) {
            if (
              moment(credit_note.emission_date).month() ===
              moment().month() - 1
            ) {
              dashboardValues.totalCreditNoteLastMonth += credit_note.total_ttc;
            }
            dashboardValues.totalCreditNoteLastYear += credit_note.total_ttc;
          }
        }
      });

      dataExpenses.forEach((expense) => {
        const check = dataAllInvoices.filter(
          (invoice) => invoice.session === expense.session
        ).length;
        if (check) {
          if (
            moment(expense.created_at).format('YYYY') ===
            moment().format('YYYY')
          ) {
            dashboardValues.totalN += expense.rate * (1 + expense.tva / 100);
            if (
              moment(expense.created_at).format('MM') === moment().format('MM')
            ) {
              dashboardValues.totalM += expense.rate * (1 + expense.tva / 100);
            }
          } else if (
            moment(expense.created_at).year() ===
            moment().year() - 1
          ) {
            if (moment(expense.created_at).month() === moment().month() - 1) {
              dashboardValues.totalM_1 +=
                expense.rate * (1 + expense.tva / 100);
            }
            dashboardValues.totalN_1 += expense.rate * (1 + expense.tva / 100);
          }
        }
      });
      dashboardValues.totalInterThisYear = dashboardValues.totalN;
      dashboardValues.totalInterLastYear = dashboardValues.totalN_1;
      dashboardValues.totalInterThisMonth = dashboardValues.totalM;
      dashboardValues.totalInterLastMonth = dashboardValues.totalM_1;

      setDashboardData({ ...dashboardValues, dataSessions });
    } catch (error) {
      if (error.response) message(error.response.status);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await setData();
    })();
  }, []);

  return (
    <>
      <Row gutter={[20, 20]} style={{ margin: 10 }}>
        <Col
          xs={24}
          sm={{ span: colSpan * 2 }}
          xl={{ span: colSpan }}
          className="indicator-large-col"
        >
          <Marge
            isLoading={isLoading}
            indicatorsColors={indicatorsColors}
            purpose="marge"
            dashboardData={dashboardData}
            monthTab={monthTab}
          />
        </Col>
        <Col
          xs={24}
          sm={{ span: colSpan * 2 }}
          xl={{ span: colSpan }}
          className="indicator-large-col"
        >
          <CaHT
            isLoading={isLoading}
            indicatorsColors={indicatorsColors}
            purpose="caHT"
            dashboardData={dashboardData}
            monthTab={monthTab}
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]} style={{ margin: 10 }}>
        <Col
          xs={24}
          sm={{ span: colSpan * 2 }}
          xl={{ span: colSpan }}
          className="indicator-large-col"
        >
          {!isLoading && (
            <Donut
              isLoading={isLoading}
              indicatorsColors={indicatorsColors}
              purpose="caHT"
              dashboardData={dashboardData}
              monthTab={monthTab}
            />
          )}
        </Col>
        <Col
          xs={24}
          sm={{ span: colSpan * 2 }}
          xl={{ span: colSpan }}
          className="indicator-large-col"
        >
          <CaPoten
            sessionData={dashboardData?.dataSessions}
            isLoading={isLoading}
            indicatorsColors={indicatorsColors}
            purpose="caPoten"
          />
        </Col>
      </Row>
      <Row gutter={[20, 20]} style={{ margin: 10 }}>
        <Col
          xs={24}
          sm={{ span: colSpan * 2 }}
          xl={{ span: colSpan }}
          className="indicator-large-col"
        >
          <LastDoc
            isLoading={isLoading}
            indicatorsColors={indicatorsColors}
            purpose="lastDoc"
          />
        </Col>
        <Col
          xs={24}
          sm={{ span: colSpan * 2 }}
          xl={{ span: colSpan }}
          className="indicator-large-col"
        >
          <LastDue
            isLoading={isLoading}
            indicatorsColors={indicatorsColors}
            purpose="lastDue"
          />
        </Col>
      </Row>
    </>
  );
};

IndicatorWrapper.propTypes = {
  colSpan: PropTypes.number,
  refresh: PropTypes.bool
};

IndicatorWrapper.defaultProps = {
  colSpan: 12,
  refresh: false
};

export default IndicatorWrapper;
