import React, { useEffect, useState } from 'react';
import {
  Tooltip,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Input,
  List,
  message,
  Popconfirm,
  Radio,
  Rate,
  Row,
  Space
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import {
  AlignLeftOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  TableOutlined,
  UnorderedListOutlined,
  UpOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { PageHeaderCustom } from '../../../components';
import useAuthContext from '../../../contexts/AuthContext';
import ChoiceGridTable from './ChoiceGridTable';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const UpdateQuiz = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const [quiz, setQuiz] = useState({});
  const [questions, setQuestions] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [markBase, setMarkBase] = useState(100);
  const [errors, setErrors] = useState([]);
  const { notification } = ErrorStatusCode();

  const getQuiz = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/quizzes/${id}` });
      setQuiz(data);
      setQuestions(
        data.questions.map((el, index) => ({
          ...el,
          index
        }))
      );
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const updateQuiz = async (body, isPushSaveButton) => {
    try {
      if (isPushSaveButton) {
        await dispatchAPI('PATCH', { url: `/quizzes/${id}`, body });
        message.success(
          t('sessions.quizzes.messages.quiz_update', {
            name: quiz.name.toLowerCase()
          })
        );
      } else await dispatchAPI('PATCH', { url: `/quizzes/${id}`, body });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const createQuizModel = async (body) => {
    try {
      await dispatchAPI('POST', { url: `/quizzes`, body });
      message.success(
        t('sessions.quizzes.messages.save_as_model', {
          name: body.name.toLowerCase()
        })
      );
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const addQuestionToQuiz = (typeQuestion) => {
    const newQuestions = [...questions];
    switch (typeQuestion) {
      case 'CHOICE_GRID':
        newQuestions.push({
          title: 'Question',
          type: typeQuestion,
          answer_options: [
            {
              title: '1'
            },
            {
              title: '2'
            },
            {
              title: '3'
            }
          ],
          questions_list: [
            {
              title: '1'
            },
            {
              title: '2'
            },
            {
              title: '3'
            }
          ]
        });
        break;
      case 'SCORE':
        newQuestions.push({
          title: 'Question',
          type: typeQuestion
        });
        break;
      case 'TEXT_AREA':
        newQuestions.push({
          title: 'Question',
          type: typeQuestion
        });
        break;
      default: {
        newQuestions.push({
          title: 'Question',
          type: typeQuestion,
          answer_options: [
            {
              title: '1'
            },
            {
              title: '2'
            },
            {
              title: '3'
            }
          ]
        });
      }
    }
    setQuestions(newQuestions);
  };

  const changeIndexQuestion = (indexQuestion, type) => {
    const newQuestions = [...questions];
    if (type === 'ascend') {
      const finalIndex = indexQuestion - 1;
      newQuestions.splice(
        finalIndex,
        0,
        newQuestions.splice(indexQuestion, 1)[0]
      );
    } else if (type === 'descend') {
      const finalIndex = indexQuestion + 1;
      newQuestions.splice(
        finalIndex,
        0,
        newQuestions.splice(indexQuestion, 1)[0]
      );
    }
    setQuestions(newQuestions);
  };

  const changeIndexAnswerOption = (indexQuestion, indexAnswer, type) => {
    const newQuestions = [...questions];
    if (type === 'ascend') {
      const finalIndex = indexAnswer - 1;
      newQuestions[indexQuestion].answer_options.splice(
        finalIndex,
        0,
        newQuestions[indexQuestion].answer_options.splice(indexAnswer, 1)[0]
      );
    } else if (type === 'descend') {
      const finalIndex = indexAnswer + 1;
      newQuestions[indexQuestion].answer_options.splice(
        finalIndex,
        0,
        newQuestions[indexQuestion].answer_options.splice(indexAnswer, 1)[0]
      );
    }
    setQuestions(newQuestions);
  };

  const updateQuestion = (index, value) => {
    const newquest = [...questions];
    newquest[index].title = value;
    setQuestions(newquest);
  };

  const updateAnswerOptions = (indexQuestion, index, value) => {
    const newquest = [...questions];
    newquest[indexQuestion].answer_options[index].title = value;
    setQuestions(newquest);
  };

  const addAnswerOption = (index) => {
    const newQuestions = [...questions];
    newQuestions[index].answer_options.push({
      title: JSON.stringify(questions[index].answer_options.length + 1)
    });
    setQuestions(newQuestions);
  };

  const deleteAnswerOption = (index, indexAnswer) => {
    const newquest = [...questions];
    newquest[index].answer_options = questions[index].answer_options.filter(
      (el, indexAn) => indexAn !== indexAnswer
    );
    setQuestions(newquest);
  };

  const saveQuiz = async () => {
    const isPushSaveButton = true;
    const listErrors = [];
    questions.forEach((question) => {
      if (quiz.type === 'training_quiz') {
        if (
          question.type === 'MULTI_CHOICE' ||
          question.type === 'UNIQUE_CHOICE'
        ) {
          const answers = [];
          question.answer_options.forEach((answer) => {
            if (answer.isRight && answer.isRight === true) {
              answers.push(answer.isRight);
            }
          });
          if (!answers.length) {
            listErrors.push(question._id);
          }
        }
      }
    });
    setErrors(listErrors);

    if (listErrors.length) {
      message.error(t('sessions.quizzes.messages.missing_mark'));
    } else {
      await updateQuiz({ mark_base: markBase, questions }, isPushSaveButton);

      history.goBack();
    }
  };

  const deleteQuestionFromQuiz = (key) => {
    const newQuestions = questions.filter((_, index) => index !== key);
    const isPushSaveButton = false;

    setQuestions(newQuestions);
    updateQuiz(
      { mark_base: markBase, questions: newQuestions },
      isPushSaveButton
    );
  };

  const updateIsQuestionOption = (index, indexAnswer, value, type) => {
    const newQuestions = [...questions];
    if (type === 'UNIQUE_CHOICE') {
      newQuestions[index].answer_options.forEach((el, indexEl) => {
        newQuestions[index].answer_options[indexEl].isRight = false;
      });
      newQuestions[index].answer_options[indexAnswer].isRight = value;
    } else {
      newQuestions[index].answer_options[indexAnswer].isRight = value;
    }
    setQuestions(newQuestions);
  };

  const addModel = async () => {
    const body = {
      name: quiz.name,
      type: `${quiz.type}_model`,
      mark_base: markBase,
      questions
    };
    await createQuizModel(body);
  };

  useEffect(() => {
    (async () => {
      await getQuiz();
    })();
  }, [refresh]);

  const checkRender = (type, options, indexQuestion, questionsOptions) => {
    switch (type) {
      case 'UNIQUE_CHOICE':
        return (
          <>
            {options.map((el, index) => {
              return (
                <>
                  <Row>
                    <Col span={24}>
                      <Button
                        type="link"
                        danger
                        icon={<MinusCircleOutlined />}
                        onClick={() => deleteAnswerOption(indexQuestion, index)}
                      />
                      <Radio disabled />
                      <Input
                        onChange={(e) =>
                          updateAnswerOptions(
                            indexQuestion,
                            index,
                            e.target.value
                          )
                        }
                        className="quiz-input"
                        bordered={false}
                        value={el.title}
                      />
                      <Tooltip
                        title={t('sessions.quizzes.detail.change_position')}
                      >
                        <Button
                          type="link"
                          disabled={index === 0}
                          onClick={() =>
                            changeIndexAnswerOption(
                              indexQuestion,
                              index,
                              'ascend'
                            )
                          }
                          icon={<UpOutlined />}
                        />
                        <Button
                          type="link"
                          disabled={index === options.length - 1}
                          onClick={() =>
                            changeIndexAnswerOption(
                              indexQuestion,
                              index,
                              'descend'
                            )
                          }
                          icon={<DownOutlined />}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                  {(quiz.type === 'training_quiz_model' ||
                    quiz.type === 'training_quiz') && (
                    <Row>
                      <Col span={24}>
                        <Space>
                          <span>
                            {t('sessions.quizzes.detail.is_good_response')}
                          </span>
                          <Checkbox
                            checked={el.isRight}
                            onChange={(e) =>
                              updateIsQuestionOption(
                                indexQuestion,
                                index,
                                e.target.checked,
                                type
                              )
                            }
                          />
                        </Space>
                      </Col>
                    </Row>
                  )}
                </>
              );
            })}
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => addAnswerOption(indexQuestion)}
            >
              {t('sessions.quizzes.detail.add_response')}
            </Button>
          </>
        );

      case 'MULTI_CHOICE':
        return (
          <>
            {options.map((el, index) => (
              <>
                <Row>
                  <Col span={24}>
                    <Button
                      type="link"
                      danger
                      icon={<MinusCircleOutlined />}
                      onClick={() => deleteAnswerOption(indexQuestion, index)}
                    />
                    <Checkbox disabled />
                    <Input
                      className="quiz-input"
                      onChange={(e) =>
                        updateAnswerOptions(
                          indexQuestion,
                          index,
                          e.target.value
                        )
                      }
                      bordered={false}
                      value={el.title}
                    />
                    <Tooltip
                      title={t('sessions.quizzes.detail.change_position')}
                    >
                      <Button
                        type="link"
                        disabled={index === 0}
                        onClick={() =>
                          changeIndexAnswerOption(
                            indexQuestion,
                            index,
                            'ascend'
                          )
                        }
                        icon={<UpOutlined />}
                      />
                      <Button
                        type="link"
                        disabled={index === options.length - 1}
                        onClick={() =>
                          changeIndexAnswerOption(
                            indexQuestion,
                            index,
                            'descend'
                          )
                        }
                        icon={<DownOutlined />}
                      />
                    </Tooltip>
                  </Col>
                </Row>
                {(quiz.type === 'training_quiz_model' ||
                  quiz.type === 'training_quiz') && (
                  <Row>
                    <Col span={24}>
                      <Space>
                        <span>
                          {t('sessions.quizzes.detail.is_good_response')}
                        </span>
                        <Checkbox
                          checked={el.isRight}
                          onChange={(e) =>
                            updateIsQuestionOption(
                              indexQuestion,
                              index,
                              e.target.checked,
                              type
                            )
                          }
                        />
                      </Space>
                    </Col>
                  </Row>
                )}
              </>
            ))}
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => addAnswerOption(indexQuestion)}
            >
              {t('sessions.quizzes.detail.add_response')}
            </Button>
          </>
        );

      case 'TEXT_AREA':
        return <TextArea disabled />;

      case 'SCORE':
        return <Rate disabled />;

      case 'CHOICE_GRID':
        return (
          <ChoiceGridTable
            options={options}
            questionsOptions={questionsOptions}
            questions={questions}
            setQuestions={setQuestions}
            indexQuestion={indexQuestion}
            addAnswerOption={addAnswerOption}
            deleteAnswerOption={deleteAnswerOption}
            updateAnswerOptions={updateAnswerOptions}
            updateQuiz={updateQuiz}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
      default:
        break;
    }
    return <></>;
  };
  return (
    <>
      <PageHeaderCustom
        title={t('sessions.updateEval.name', {
          name: quiz.name
        })}
      />
      <Card style={{ marginTop: 20 }}>
        <List
          grid={{ column: 1 }}
          dataSource={questions}
          renderItem={(item, index) => (
            <List.Item>
              <Card
                bordered={false}
                extra={
                  <>
                    <Tooltip
                      title={t('sessions.quizzes.detail.change_position')}
                    >
                      <Button
                        type="link"
                        disabled={index === 0}
                        onClick={() => changeIndexQuestion(index, 'ascend')}
                        icon={<UpOutlined />}
                      />
                      <Button
                        type="link"
                        disabled={index === questions.length - 1}
                        onClick={() => changeIndexQuestion(index, 'descend')}
                        icon={<DownOutlined />}
                      />
                    </Tooltip>
                    <Divider type="vertical" />
                    <Popconfirm
                      title={t('datatable.column.action.delete.title')}
                      okText={t('datatable.column.action.delete.ok')}
                      okButtonProps={{ danger: true }}
                      cancelText={t('datatable.column.action.delete.cancel')}
                      onConfirm={() => deleteQuestionFromQuiz(index)}
                      icon={<WarningOutlined />}
                    >
                      <Button type="link" danger>
                        <DeleteOutlined />
                      </Button>
                    </Popconfirm>
                  </>
                }
                title={
                  <Input
                    onChange={(e) => updateQuestion(index, e.target.value)}
                    bordered={false}
                    value={item.title}
                    suffix={
                      <Button type="link">
                        <EditOutlined />
                      </Button>
                    }
                  />
                }
              >
                {checkRender(
                  item.type,
                  item.answer_options,
                  index,
                  item.questions_list
                )}
              </Card>
            </List.Item>
          )}
        />
      </Card>
      <Card
        title={t('sessions.quizzes.detail.add_field')}
        headStyle={{ textAlign: 'center' }}
        bodyStyle={{ margin: 'auto' }}
      >
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center' }} span={8}>
            <Button
              icon={<UnorderedListOutlined />}
              type="link"
              onClick={() => addQuestionToQuiz('UNIQUE_CHOICE')}
            >
              {t('sessions.quizzes.detail.question_models.unique_choice')}
            </Button>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }} span={8}>
            <Button
              icon={<UnorderedListOutlined />}
              type="link"
              onClick={() => addQuestionToQuiz('MULTI_CHOICE')}
            >
              {t('sessions.quizzes.detail.question_models.multi_choice')}
            </Button>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }} span={8}>
            <Button
              icon={<AlignLeftOutlined />}
              type="link"
              onClick={() => addQuestionToQuiz('TEXT_AREA')}
            >
              {t('sessions.quizzes.detail.question_models.text_area')}
            </Button>
          </Col>
        </Row>
        <Row justify="center">
          <Col style={{ display: 'flex', justifyContent: 'center' }} span={12}>
            <Button
              icon={<AlignLeftOutlined />}
              type="link"
              onClick={() => addQuestionToQuiz('SCORE')}
            >
              {t('sessions.quizzes.detail.question_models.score')}
            </Button>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }} span={12}>
            <Button
              icon={<TableOutlined />}
              type="link"
              onClick={() => addQuestionToQuiz('CHOICE_GRID')}
            >
              {t('sessions.quizzes.detail.question_models.choice_grid')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center' }} span={24}>
            <Button type="primary" onClick={() => saveQuiz()}>
              {t('sessions.quizzes.detail.save')}
            </Button>
          </Col>
          <Col style={{ display: 'flex', justifyContent: 'center' }} span={24}>
            <Button type="link" onClick={() => addModel()}>
              {t('sessions.quizzes.detail.add_quiz_to_model')}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default UpdateQuiz;
