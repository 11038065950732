import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Card, Button, Divider, Popconfirm, Skeleton, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import { ButtonAdd, SearchBar } from '../../../utils/constants/customButton';
import Datatable from '../../../components/DataTable/Datatable';
import useColumns from '../Contacts/columns';
import Columns from '../Trainees/columns';
import sessionColumns from '../../Sessions/columns';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import NewContactDrawer from '../Contacts/NewContactDrawer';
import NewTraineeDrawer from '../Trainees/NewTraineeDrawer';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const DetailCompany = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [company, setCompany] = useState({});
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [refresh, setRefresh] = useState(false);
  const [visibilityContactDrawer, setVisibilityContactDrawer] = useState(false);
  const [visibilityTraineeDrawer, setVisibilityTraineeDrawer] = useState(false);
  const columnsContact = useColumns();
  const columnsTrainee = Columns();
  const columnsSession = sessionColumns();
  const { notification } = ErrorStatusCode();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', { url: `/companies/${id}` });
      setCompany(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const deleteCompany = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/companies/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) {
        ErrorStatusCode(e.response.status);
      }
    }
  };

  const setNewContactDrawerVisibility = () => {
    setVisibilityContactDrawer(!visibilityContactDrawer);
  };

  const setNewTraineeDrawerVisibility = () => {
    setVisibilityTraineeDrawer(!visibilityTraineeDrawer);
  };

  return (
    <>
      <NewTraineeDrawer
        company={company}
        isVisible={visibilityTraineeDrawer}
        setVisibilityTraineesDrawer={setNewTraineeDrawerVisibility}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <NewContactDrawer
        company={company}
        isVisible={visibilityContactDrawer}
        setVisibilityContactDrawer={setNewContactDrawerVisibility}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <PageHeaderCustom
        title={t(`companies.show.title`)}
        withSubRoutes
        extra={
          <>
            <Link
              to={{
                pathname: `/datas/companies/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCompany}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />

      <Card>
        <Skeleton loading={isLoading} active>
          <DescriptionList data={listContent(company)} translate />
        </Skeleton>
      </Card>

      <Divider orientation="left">
        <span>{t('companies.show.contactListTitle')}</span>
      </Divider>
      <Card>
        <Row>
          <Col span={12}>
            <SearchBar setSearchValue={setSearchValue} />
          </Col>
          <Col offset={8} span={4}>
            <ButtonAdd onClick={setNewContactDrawerVisibility} />
          </Col>
        </Row>
        <Datatable
          forceRefresh={refresh}
          resourceName="contacts"
          path={`${routes.DATAS}${subRoutes.DATAS.CONTACTS}`}
          columns={columnsContact}
          populate="my_company"
          extraQuery={`my_company=${id}`}
          searchValue={searchValue}
        />
      </Card>
      <Divider orientation="left">
        <span>{t('companies.show.traineesListTitle')}</span>
      </Divider>
      <Card>
        <Row>
          <Col span={12}>
            <SearchBar setSearchValue={setSearchValue} />
          </Col>
          <Col offset={8} span={4}>
            <ButtonAdd onClick={setNewTraineeDrawerVisibility} />
          </Col>
        </Row>
        <Datatable
          forceRefresh={refresh}
          resourceName="trainees"
          path={`${routes.DATAS}${subRoutes.DATAS.TRAINEES}`}
          columns={columnsTrainee.headers}
          extraQuery={`company=${id}`}
          searchValue={searchValue}
        />
      </Card>

      <Divider orientation="left">
        <span>{t('companies.show.sessionsListTitle')}</span>
      </Divider>

      <Card>
        <Row>
          <Col span={12}>
            <SearchBar setSearchValue={setSearchValue} />
          </Col>
        </Row>

        <Datatable
          forceRefresh={refresh}
          resourceName="sessions"
          path={`${routes.SESSIONS}`}
          columns={columnsSession}
          extraQuery={`idCompany=${id}`}
          searchValue={searchValue}
        />
      </Card>
    </>
  );
};

export default DetailCompany;
