import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, message, Space, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useColumns from '../../Catalog/Program/columns';
import { routes, subRoutes } from '../../../utils/constants/routes';

const ProgramsTable = ({
  updateSession,
  fetchData,
  programs,
  setBlocs,
  session
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { columns } = useColumns();
  const { pathname } = useLocation();
  const history = useHistory();
  const splitUrl = pathname.split('/');

  const addProgramToSession = async () => {
    setLoading(true);
    const { data } = await dispatchAPI('GET', {
      url: `programs/${selectedRowKeys}`
    });

    if (data.files.length) {
      const newestObject = data.files.reduce((acc, obj) => {
        if (!acc || obj.date > acc.date) {
          return obj;
        }
        return acc;
      }, null);

      await updateSession({
        $push: {
          files: {
            type: 'programs',
            filename: newestObject.filename,
            date: new Date(),
            _id: newestObject._id,
            program: selectedRowKeys
          }
        }
      });
    }
    await updateSession({ program: selectedRowKeys });
    message.success('Programme ajouté !');
    await fetchData();
    setLoading(false);
  };

  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys);
    if (splitUrl[1] === 'catalog') {
      setBlocs(keys);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (keys) => onSelectChange(keys)
  };
  return (
    <>
      <div>
        {splitUrl[1] !== 'catalog' ? (
          <Space style={{ float: 'right', marginBottom: 10 }}>
            <Button
              onClick={addProgramToSession}
              type="primary"
              loading={isLoading}
            >
              {t('sessions.form.button.program')}
            </Button>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() =>
                history.push(
                  `${routes.CATALOG}${subRoutes.CATALOG.PROGRAMS}/create`,
                  { session }
                )
              }
            >
              Nouveau
            </Button>
          </Space>
        ) : null}
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={programs}
        />
      </div>
    </>
  );
};

export default ProgramsTable;

ProgramsTable.propTypes = {
  updateSession: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  programs: PropTypes.arrayOf({}).isRequired,
  setBlocs: PropTypes.func.isRequired,
  session: PropTypes.shape({}).isRequired
};
