// I commented all the code to add a column to the kanban
// following Diane's demand.
// Do not delete this code in case of it will be used later.
import React, { useEffect, useState } from 'react';
import { PlusOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Input,
  Row,
  // message,
  Switch,
  Alert,
  Spin,
  Select
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import useBoxContext from './SessionsContext';
import SessionKanbanColumns from './SessionKanbanColumns';
import { routes } from '../../../utils/constants/routes';
import KanbanBackground from '../../../assets/images/kanban-background_andrew-neel.png';
import useAuthContext from '../../../contexts/AuthContext';
// import { AddColIcon } from '../../../utils/constants/customIcon';
import CheckTrialDays from '../../../utils/checkTrialDays';

// const Cole = styled.div`
//   height: auto;
//   width: 200px;
//   min-width: 200px;
//   margin: 5px;
//   overflow-y: hidden;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: transparent;
//   z-index: 9;
//   .anticon {
//     font-size: 5em;
//     color: #fff;
//     cursor: pointer;
//     transition: all 0.3s;
//     opacity: 0.9;
//     &:hover {
//       opacity: 0.6;
//     }
//   }
// `;

const KanbanWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  position: relative;
  height: calc(100vh - 250px);
  margin: 0 0 -18px -4px;
  background: url(${KanbanBackground}) no-repeat;
  background-size: cover;
  background-position-y: 60%;
`;

const { Search } = Input;
const { Option } = Select;

const SessionsKanbanBoard = () => {
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const history = useHistory();
  const [fencedView, setFencedView] = useState(false);
  const [searchType, setSearchType] = useState('session');
  const {
    searchResource,
    searchValue,
    searchTypeValue,
    fetchKanbanColumns,
    kanbanCols,
    fetchData,
    // dataRefresh,
    // setDataRefresh,
    fetching
  } = useBoxContext();

  const [newKanbanCol, setNewKanbanCol] = useState({
    label: 'Nouvelle colonne',
    icon: null,
    type: 'sessions',
    show: true,
    color: '#E4EAEB',
    index: ''
  });

  // const addKanbanCol = async () => {
  //   try {
  //     await dispatchAPI('POST', { url: '/kanbans', body: newKanbanCol });
  //     setDataRefresh(!dataRefresh);
  //     await fetchKanbanColumns();
  //   } catch (e) {
  //     message.error(e.message);
  //   }
  // };

  const sortedKanbanCols = kanbanCols
    .sort((a, b) => {
      return b.index - a.index;
    })
    .reverse();

  const handleChange = (value) => {
    setSearchType(value);
  };

  useEffect(() => {
    const col = { ...newKanbanCol };
    col.index =
      kanbanCols[kanbanCols.length - 1] &&
      kanbanCols[kanbanCols.length - 1].index + 1;
    setNewKanbanCol(col);
  }, [kanbanCols]);

  useEffect(() => {
    (async () => {
      const fieldsArray = [
        'name',
        'session_type',
        'status.label',
        'status.color',
        'date',
        'customers.customer.name'
      ];
      const fieldsString = fieldsArray.join(',');

      await fetchData({
        projectionFields: fieldsString
      });
    })();
  }, [fetchData]);

  useEffect(() => {
    (async () => {
      await fetchKanbanColumns();
    })();
  }, []);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[8, 16]}>
        <Row style={{ flexWrap: 'nowrap' }}>
          <Select
            defaultValue={searchTypeValue || 'session'}
            style={{ width: '30%' }}
            onChange={handleChange}
          >
            <Option value="session">{t('basiclayout.menu.sessions')}</Option>
            <Option value="customer">{t('basiclayout.menu.customers')}</Option>
            <Option value="trainee">{t('basiclayout.menu.trainees')}</Option>
            <Option value="contributor">
              {t('basiclayout.menu.contributors')}
            </Option>
          </Select>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={(value) => searchResource(value, searchType)}
            style={{ marginLeft: '-4px', marginRight: 8 }}
          />
          <Button
            type="primary"
            disabled={
              !CheckTrialDays(user) &&
              user?.organization?.current_subscription === undefined
            }
            onClick={() => history.push(`${routes.SESSIONS}/create`)}
          >
            {t('buttons.create')}
            <PlusOutlined />
          </Button>
        </Row>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Col>
            {CheckTrialDays(user) ? (
              <Alert
                showIcon
                message={<span>{t('profile.trial_period')}</span>}
                type="warning"
              />
            ) : (
              user?.organization?.current_subscription === undefined && (
                <Alert
                  showIcon
                  message={
                    <span>
                      {user.organization.current_subscription
                        ? t('profile.minSubscription')
                        : t('buttons.infos.subscriptions')}
                      <Button
                        onClick={() => {
                          history.push('/profile');
                          localStorage.setItem('subscriptionIndex', '2');
                        }}
                        type="link"
                        icon={<ArrowRightOutlined />}
                      />
                    </span>
                  }
                  type="warning"
                />
              )
            )}
          </Col>
        </Row>
        <Col>
          <span
            style={{
              margin: 5,
              color: ' var(--primaryColor)',
              fontSize: 17
            }}
          >
            {t('sessions.show.archived')}
          </span>
          <Switch
            onChange={() => setFencedView(!fencedView)}
            checked={fencedView}
          />
        </Col>
      </Row>
      <Spin spinning={fetching}>
        <KanbanWrapper>
          <div
            style={{
              width: 'fit-content',
              display: 'flex',
              justifyContent: 'left',
              flexWrap: 'nowrap'
            }}
          >
            {sortedKanbanCols
              .filter((col) => col.show)
              .filter((l) => (!fencedView ? l.label !== 'ARCHIVEE' : l))
              .map((col) => (
                <SessionKanbanColumns column={col} key={col._id} />
              ))}
            {/* <Cole>
              <Button
                type="link"
                icon={<AddColIcon />}
                style={{ height: 'fit-content', width: 'fit-content' }}
                onClick={() => addKanbanCol()}
              />
            </Cole> */}
          </div>
        </KanbanWrapper>
      </Spin>
    </>
  );
};

export default SessionsKanbanBoard;
