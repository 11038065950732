import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Modal,
  Row,
  Col,
  Select,
  Button,
  message,
  Input,
  Form,
  Switch,
  Divider,
} from 'antd';
import useAuthContext from '../../../contexts/AuthContext';

const { Option } = Select;

const ModalSendMails = ({
  isModalVisible,
  setIsModalVisible,
  module,
  purpose,
  forceRefresh,
  setForceRefresh,
}) => {
  const { dispatchAPI } = useAuthContext();
  const [distance, setDistance] = useState(false);
  const [training, setTraining] = useState([]);
  const [location, setLocation] = useState(null);
  const [main, setMain] = useState(false);
  const [place, setPlace] = useState(null);

  const fetchData = async () => {
    const { data } = await dispatchAPI('GET', { url: '/training-places' });
    setTraining(data);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async () => {
    try {
      const body = { training_place: { distance: true } };
      if (!distance) {
        if (!main) {
          if (!place) {
            message.error('Veuillez selectionner un lieu de formation');
            return;
          }
          body.training_place = { training_place: place };
        } else {
          if (!location) {
            message.error('Veuillez entrer une adresse');
            return;
          }
          body.training_place = { location };
        }
      }
      await dispatchAPI('PATCH', {
        url: `/module/${module._id}`,
        body,
      });
      message.success(`${purpose} du lieu de formation réussi`);
      setForceRefresh(!forceRefresh);
      setIsModalVisible(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setMain(module && module.training_place && module.training_place.location);
    setDistance(
      module && module.training_place && module.training_place.distance
    );
    setLocation(
      module && module.training_place && module.training_place.location
    );
    setPlace(
      module &&
        module.training_place &&
        module.training_place.training_place &&
        module.training_place.training_place._id
    );
  }, [module]);

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleOk}
        title={'Lieu de formation'}
        width={800}>
        <div
          style={{ display: 'flex', marginTop: 10, justifyContent: 'center' }}>
          <p style={{ marginRight: '10px' }}>En présentiel</p>
          <Switch
            onChange={value => setDistance(value)}
            defaultChecked={
              module && module.training_place && module.training_place.distance
            }
          />
          <p style={{ marginLeft: '10px' }}>En distanciel</p>
        </div>

        {!distance ? (
          <>
            <Divider title={'test'}></Divider>
            <div style={{ width: '50%', margin: 'auto' }}>
              {main ? (
                <Input
                  onChange={e => setLocation(e.target.value)}
                  defaultValue={
                    module &&
                    module.training_place &&
                    module.training_place.location
                  }
                />
              ) : (
                <Select
                  style={{ width: '100%' }}
                  onChange={value => setPlace(value)}
                  value={place && place.toString()}>
                  {training.map(t => (
                    <Option key={t._id} value={t._id.toString()}>
                      {t.name}
                    </Option>
                  ))}
                </Select>
              )}
              <div
                style={{
                  display: 'flex',
                  marginTop: '10px',
                  justifyContent: 'center',
                }}>
                <p style={{ marginRight: '10px' }}>Choisir un lieu existant</p>
                <Switch
                  onChange={value => setMain(value)}
                  defaultChecked={
                    module &&
                    module.training_place &&
                    module.training_place.location
                  }
                />
                <p style={{ marginLeft: '10px' }}>Entrer une autre adresse</p>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default ModalSendMails;
