import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateUpdateSession from './CreateUpdateSession';
import DetailsSession from './detailsSession';
import SessionsAddContributors from './sessionContributors/SessionsAddContributors';
import SessionsAddCustomers from './sessionCustomers/SessionsAddCustomer';
import RatesCustomerList from './sessionCustomers/ratesCustomerList';
import ModifyRatesForm from './sessionModule/ModifyRatesForm';
import Sessions from './Sessions';
import DetailQuiz from './Quizzes/DetailQuiz';
import UpdateQuiz from './Quizzes/UpdateQuiz';
import CreateInvoice from './invoices/createInvoice';
import CreatePayment from './invoices/payment/CreatePayment';
import CreateCreditNote from '../Accounting/CreditNotes/CreateCreditNote';
import CreateAndUpdateCharge from './invoices/Charges/CreateAndUpdateCharge';

const SessionsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateSession purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateSession purpose="edit" />}
      />
      <Route
        path={`${path}/show/:id/contributors/create`}
        render={() => <SessionsAddContributors purpose="create" />}
      />
      <Route
        path={`${path}/show/:id/contributors/edit/:id`}
        render={() => <SessionsAddContributors purpose="edit" />}
      />
      <Route
        path={`${path}/show/:id/customers/company/create`}
        render={() => <SessionsAddCustomers purpose="create" />}
      />
      <Route
        path={`${path}/show/:id/customers/company/edit/:id`}
        render={() => <SessionsAddCustomers purpose="edit" />}
      />
      <Route
        path={`${path}/show/:id/customers/private/create`}
        render={() => <SessionsAddCustomers purpose="create" />}
      />
      <Route
        path={`${path}/show/:id/customers/private/edit/:id`}
        render={() => <SessionsAddCustomers purpose="edit" />}
      />
      <Route
        path={`${path}/show/:id/modules/create`}
        render={() => <RatesCustomerList purpose="create" />}
      />
      <Route
        path={`${path}/show/:id/modules/:id/edit/:id`}
        render={() => <ModifyRatesForm />}
      />
      <Route
        path={`${path}/show/:id/quizzes/edit/:id`}
        render={() => <UpdateQuiz />}
      />
      <Route
        path={`${path}/show/:id/quizzes/:id`}
        render={() => <DetailQuiz />}
      />

      <Route
        path={`${path}/show/:id/invoices/:ref/:id`}
        render={() => <CreateInvoice />}
      />
      <Route
        path={`${path}/show/:id/charges/create`}
        render={() => <CreateAndUpdateCharge purpose="create" />}
      />
      <Route
        path={`${path}/show/:id/charges/edit/:id`}
        render={() => <CreateAndUpdateCharge purpose="edit" />}
      />
      <Route
        path={`${path}/show/:id/payment/:id/:invoiceType`}
        render={() => <CreatePayment />}
      />
      <Route
        path={`${path}/show/:sessionId/credit-note/:customerId/:ref/:invoiceId`}
        render={() => <CreateCreditNote />}
      />
      <Route path={`${path}/show/:id`} component={DetailsSession} />
      <Route path={`${path}`} render={() => <Sessions />} />
    </Switch>
  );
};

export default SessionsRouter;
