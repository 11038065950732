import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  List,
  message,
  Row,
  Select,
  Spin,
  Tag
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  DownloadOutlined,
  DownOutlined,
  LoadingOutlined,
  SendOutlined
} from '@ant-design/icons';
import moment from 'moment';
import ModalUploadFiles from '../../../Catalog/Program/modalUploadFiles';
import useAuthContext from '../../../../contexts/AuthContext';
import ListSessionFiles from '../ListSessionFiles';
import ModalSendMails from '../../Convocations/modalSendMails';
import ModalGeneratingFile from '../../Convocations/modalGeneratingFile';
import ErrorStatusCode from '../../../../utils/ErrorStatusCode';
import MenuDropDownCGV from './MenuDropDownCGV';
import MenuDropDown from './MenuDropDown';
// Function
import getCGVTemplates from './utils/getCGVTemplates';
import getCInternalRulesTemplates from './utils/getCInternalRulesTemplates';
import getFilesPrivate from './utils/getFilesPrivate';
import getFilesCompany from './utils/getFilesCompany';
import showModal from './utils/showModal';
import checkIfConventionFiles from './utils/checkIfConventionFiles';

const { Option } = Select;

const GenerateConventions = ({
  customersList,
  fetchData,
  session,
  updateSession,
  contacts,
  forceRefresh,
  setForceRefresh,
  emails
}) => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalMailVisible, setIsModalMailVisible] = useState(false);
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [conventionFile, setConventionFile] = useState([]);
  const [generatedFile, setGeneratedFile] = useState({});
  const [isConventionFiles, setIsConventionFiles] = useState({});
  const [templatesPrivate, setTemplatesPrivate] = useState([]);
  const [templatesCompany, setTemplatesCompany] = useState([]);
  const [sendType, setSendType] = useState('one');
  const [generateStatus, setGenerateStatus] = useState(false);
  const [internalRulesList, setInternalRulesList] = useState([]);
  const [users, setUsers] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const [cgvTemplates, setCGVTemplates] = useState([]);
  const [globalEmail, setGlobalEmail] = useState(true);
  const { notification } = ErrorStatusCode();

  const generateFile = async ({ id, name, customId, fileType }) => {
    try {
      message.info(t('sessions.messages.is_generating'), 5);
      setIsLoading(true);
      setGenerateStatus(true);
      await dispatchAPI('GET', {
        url: `/files/generate/convention/${session._id}/${customId}/${id}?fileType=${fileType}`,
        responseType: 'blob'
      });
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      setGenerateStatus(false);
      setIsLoading(false);
      await fetchData();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      getFilesCompany({ setTemplatesCompany, user, notification });
      getFilesPrivate({ setTemplatesPrivate, user, notification });
      await getCGVTemplates({
        setCGVTemplates,
        notification,
        dispatchAPI,
        user
      });
      await getCInternalRulesTemplates({
        dispatchAPI,
        setInternalRulesList,
        user,
        notification
      });
    })();
  }, []);

  const downloadCGVFile = async (type, tempId, tempName) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/cgv/${tempId}?fileType=${type}&purpose=cgv`
      });
      const bufferArray = new Uint8Array(response.data.data);
      const blob = new Blob([bufferArray], {
        type: type === 'docx' ? 'application/docx' : 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${tempName}.${type}`;
      a.click();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const generateMultiFiles = async (typeFile) => {
    try {
      message.info(t('sessions.messages.is_generating'), 5);
      setGenerateStatus(true);
      await dispatchAPI('GET', {
        url: `/multi-generate/${session._id}?customers=${JSON.stringify(
          customersList.map(({ customer }) => customer._id)
        )}&fileType=${typeFile}`
      });
      setIsModalGenerateFileVisible(!isModalGenerateFileVisible);
      await fetchData();
      setIsLoading(false);
      setGenerateStatus(false);
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const deleteFile = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${id}`
      });
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const deleteFileFromSession = async (id) => {
    const filesList = session.files.filter((el) => el._id !== id);
    await updateSession({ files: filesList });
    await deleteFile(id);
    await fetchData();
  };

  useEffect(() => {
    checkIfConventionFiles({
      customersList,
      files: session.files,
      setIsConventionFiles,
      setGeneratedFile
    });
  }, [session?.files, customersList]);

  const selectInternalRulesFile = async (value) => {
    try {
      const tmp = internalRulesList.find((el) => el._id === value);
      const internalRulesFile = {
        _id: tmp._id,
        filename: tmp.metadata.originalName
      };
      await updateSession({ internal_rules: internalRulesFile });
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) {
        ErrorStatusCode(e.response);
      }
    }
  };

  const listActions = (type, id, ref) => {
    setCustomerId(id);
    let actions = [];
    session.files.forEach((f) => {
      if (
        f.user &&
        f.user._id.toString() === id.toString() &&
        f.type === type
      ) {
        actions = [
          <>
            <Tag style={{ fontSize: 15 }} color="orange">
              <span>
                <CheckOutlined
                  style={{ margin: '4px 2px 0 0', fontSize: 14 }}
                />
                {`${t('sessions.conventions.button.generate_the')} ${moment(
                  f.date
                ).format('DD-MM-YYYY')} `}
              </span>
              <span>
                {`${t('sessions.conventions.button.format')} ${
                  f.filename.split('.')[1]
                }`}
              </span>
            </Tag>
          </>
        ];
      }
    });

    if (ref === 'Company') {
      const contactsList = contacts
        .filter(
          (el) =>
            el.my_company._id.toString() === id.toString() &&
            el.company_representative
        )
        .map((el) => el._id);
      const emailsList = emails
        .filter((el) => el.user?._id)
        .filter((el) => {
          return el.user && contactsList.includes(el.user._id?.toString());
        });
      if (emailsList.length) {
        actions.push(
          <Tag color="green">
            <span style={{ fontSize: 15 }}>
              <CheckOutlined style={{ margin: '4px 2px 0 0', fontSize: 14 }} />
              Envoyée
            </span>
          </Tag>
        );
      }
    } else {
      const email = emails
        .filter((el) => el.user?._id)
        .find(
          (el) =>
            el.user &&
            el.user._id.toString() === id.toString() &&
            el.purpose === 'conventions'
        );
      if (email) {
        actions.push(
          <Tag color="green">
            <span style={{ fontSize: 15 }}>
              <CheckOutlined style={{ margin: '4px 2px 0 0', fontSize: 14 }} />
              {t('sessions.conventions.button.send')}
            </span>
          </Tag>
        );
      }
    }
    return actions;
  };

  const returnValueRulesSelect = () => {
    if (session?.internal_rules?._id) {
      return session?.internal_rules?._id;
    }
    if (internalRulesList.length === 1) {
      return internalRulesList[0]._id;
    }
    return null;
  };

  return (
    <>
      <Row style={{ padding: '0 20px' }}>
        <Col span={24}>
          <Alert
            showIcon
            type="info"
            message={t('sessions.conventions.message.next_step')}
          />
        </Col>
      </Row>
      <Divider orientation="left">
        <span>{t('sessions.conventions.button.generate_conventions')}</span>
      </Divider>
      <Card>
        {!customerId && (
          <Col style={{ marginBottom: '20px' }}>
            <Alert
              showIcon
              type="info"
              message={t('sessions.conventions.message.no_customers')}
            />
          </Col>
        )}
        <ModalGeneratingFile
          isVisible={isModalGenerateFileVisible}
          setVisible={setIsModalGenerateFileVisible}
        />
        <ModalSendMails
          emailModel="convention_model"
          files={conventionFile}
          file={conventionFile}
          sendType={sendType}
          isSelectedDocument={false}
          globalEmail={globalEmail}
          setGlobalEmail={setGlobalEmail}
          users={users}
          isModalVisible={isModalMailVisible}
          setIsModalVisible={setIsModalMailVisible}
          emailPurpose="conventions"
          refresh={forceRefresh}
          setRefresh={setForceRefresh}
          session={session}
        />
        <Button
          style={{ marginBottom: 16 }}
          disabled={Object.keys(generatedFile).length === 0}
          type="primary"
          onClick={() => {
            setSendType('multi');
            setGlobalEmail(true);
            showModal({
              ref: 'all',
              setUsers,
              customersList,
              contacts,
              setConventionFile,
              files: session.files,
              setIsModalMailVisible,
              isModalMailVisible
            });
          }}
        >
          {t('sessions.conventions.button.send_conventions')}
        </Button>
        <Spin style={{ marginLeft: 10 }} spinning={generateStatus} />
        <Dropdown
          overlay={MenuDropDown({
            type: 'multi',
            generateMultiFiles,
            templatesPrivate,
            generateStatus,
            templatesCompany,
            generateFile,
            t
          })}
          disabled={!customersList.length}
        >
          <Button type="link" style={{ marginBottom: 10, marginLeft: 10 }}>
            {t('sessions.conventions.button.generate_conventions')}
            <DownOutlined />
          </Button>
        </Dropdown>
        <List
          size="small"
          dataSource={customersList}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  item.customer.name ||
                  `${item.customer.first_name} ${item.customer.last_name}`
                }
              />
              <span style={{ paddingRight: 50 }}>
                {item.ref === 'Company'
                  ? listActions(
                      'company_convention',
                      item.customer._id,
                      item.ref,
                      item._id
                    )
                  : listActions(
                      'private_convention',
                      item.customer._id,
                      item.ref,
                      item._id
                    )}
              </span>
              <span style={{ float: 'right' }}>
                {isLoading && item.customer._id === customerId && (
                  <LoadingOutlined />
                )}
                <Dropdown
                  overlay={() =>
                    item.ref === 'Company'
                      ? MenuDropDown({
                          type: 'company_convention',
                          id: item.customer._id,
                          ref: item.ref,
                          generateMultiFiles,
                          templatesPrivate,
                          generateStatus,
                          templatesCompany,
                          generateFile,
                          t
                        })
                      : MenuDropDown({
                          type: 'private_convention',
                          id: item.customer._id,
                          ref: item.ref,
                          generateMultiFiles,
                          templatesPrivate,
                          generateStatus,
                          templatesCompany,
                          generateFile,
                          t
                        })
                  }
                  trigger={['click']}
                >
                  <span
                    style={{
                      paddingLeft: 10,
                      fontSize: 15,
                      cursor: 'pointer'
                    }}
                  >
                    {t('sessions.conventions.button.generate')}
                    <DownOutlined style={{ marginLeft: 4 }} />
                  </span>
                </Dropdown>
                {isConventionFiles[item._id] && (
                  <>
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      onClick={() =>
                        downloadFile(
                          generatedFile[item._id].id,
                          generatedFile[item._id].fileName
                        )
                      }
                    />
                    <Divider type="vertical" />
                    <Button
                      type="link"
                      icon={<SendOutlined />}
                      onClick={() => {
                        setSendType('one');
                        setGlobalEmail(false);
                        showModal({
                          ref: item.ref,
                          id: item.customer._id,
                          document: generatedFile[item._id],
                          setUsers,
                          customersList,
                          contacts,
                          setConventionFile,
                          files: session.files,
                          setIsModalMailVisible,
                          isModalMailVisible
                        });
                      }}
                    />
                  </>
                )}
              </span>
            </List.Item>
          )}
        />
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.internal_rules.title')}</span>
      </Divider>
      <Card>
        <Select
          value={returnValueRulesSelect()}
          onSelect={(value) => selectInternalRulesFile(value)}
          style={{ width: 500 }}
        >
          {internalRulesList.map((internal_rule) => (
            <Option key={internal_rule._id} value={internal_rule._id}>
              {internal_rule.metadata.originalName}
            </Option>
          ))}
        </Select>
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.general_terms.title')}</span>
      </Divider>
      <Card>
        <Row>
          <Col span={2}>
            <Dropdown
              disabled={!cgvTemplates.length}
              trigger={['click']}
              overlay={() =>
                MenuDropDownCGV({ cgvTemplates, downloadCGVFile, t })
              }
            >
              <Button type="link">
                {t('sessions.general_terms.button.terms')}
                <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
          <Col span={12}>
            {!cgvTemplates.length && (
              <Alert
                type="warning"
                message={t('sessions.general_terms.message.no_models')}
              />
            )}
          </Col>
        </Row>
      </Card>
      <Divider orientation="left">
        <span>{t('sessions.conventions.archives')}</span>
      </Divider>
      <Card>
        <Button
          type="link"
          onClick={() => {
            setIsModalVisible(!isModalVisible);
          }}
        >
          {t('sessions.conventions.button.upload')}
        </Button>
        <ModalUploadFiles
          uploadType="sessions"
          isModalVisible={isModalVisible}
          fetchData={fetchData}
          setIsModalVisible={setIsModalVisible}
          id={session && session._id}
          fileType="convention"
        />
        {session &&
          session.files &&
          session.files.filter((el) => el.type === 'convention').length !==
            0 && (
            <ListSessionFiles
              files={session.files.filter((el) => el.type === 'convention')}
              deleteFileFromSession={deleteFileFromSession}
              downloadFile={downloadFile}
            />
          )}
      </Card>
    </>
  );
};

GenerateConventions.propTypes = {
  customersList: PropTypes.arrayOf({}).isRequired,
  session: PropTypes.shape({
    _id: PropTypes.string,
    files: PropTypes.arrayOf({}),
    internal_rules: PropTypes.shape({
      _id: PropTypes.string
    })
  }).isRequired,
  contacts: PropTypes.arrayOf({}).isRequired,
  forceRefresh: PropTypes.bool.isRequired,
  emails: PropTypes.arrayOf({}).isRequired,
  fetchData: PropTypes.func.isRequired,
  updateSession: PropTypes.func.isRequired,
  setForceRefresh: PropTypes.func.isRequired
};

export default GenerateConventions;
