import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Col, Input, message, Row } from 'antd';
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromHTML
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { useHistory, useParams } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import { PageHeaderCustom } from '../../../components';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const CreateTemplateEmail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const { notification } = ErrorStatusCode();
  const { dispatchAPI } = useAuthContext();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [email, setEmail] = useState({});
  const [modelSubject, setModelSubject] = useState('');
  const [modelTitle, setModelTitle] = useState('');

  const selectTitle = (value) => {
    setModelTitle(value);
  };

  const selectSubject = (value) => {
    setModelSubject(value);
  };

  const getEmail = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/emails/${id}` });
      setEmail(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await getEmail();
    })();
  }, []);

  useEffect(() => {
    if (Object.keys(email).length && email.data) {
      if (email.subject) setModelSubject(email.subject);
      const html = email.data && convertFromHTML(email.data);
      const state =
        html &&
        ContentState.createFromBlockArray(html.contentBlocks, html.entityMap);
      setEditorState(EditorState.createWithContent(state));
    }
  }, [email]);

  const updateModelEmail = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/emails/${id}`,
        body
      });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const addModelEmail = async () => {
    const htmlBody = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const body = {
      ...email,
      data: htmlBody,
      subject: modelSubject,
      name: modelTitle
    };
    await updateModelEmail(body);
    history.goBack();
  };

  const myToolbar = {
    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'link', 'emoji', 'history'],
    inline: {
      inDropdown: false,
      className: undefined,
      component: undefined,
      dropdownClassName: undefined,
      options: ['bold', 'italic', 'strikethrough', 'monospace']
    }
  };

  return (
    <>
      <PageHeaderCustom title={t('emails.name', { name: email?.name })} />
      <Card>
        <Row>
          <Col span={24}>
            <span>{t('emails.form.title')}</span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              value={modelTitle}
              onChange={(e) => selectTitle(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <span>Objet du message</span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Input
              value={modelSubject}
              onChange={(e) => selectSubject(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <span>Corps du message</span>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Editor
              toolbar={myToolbar}
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
          </Col>
        </Row>
        <Row>
          <Col span={4} offset={20}>
            <Button type="add" onClick={() => addModelEmail()}>
              Enregistrer
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CreateTemplateEmail;
