import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import useFields from './fields';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import ItemProgramFieldsCollapse from './itemProgramFieldsCollapse';
import { routes, subRoutes } from '../../../utils/constants/routes';

const UpdateProgram = ({ purpose }) => {
  const [trainingFieldValue, setTrainingFieldValue] = useState('');
  const { state } = useLocation();
  const [importDocs, setImportDocs] = useState(false);
  const [trainingContent, setTrainingContent] = useState([
    {
      title: '',
      list_items: [
        {
          title: ''
        },
        {
          title: ''
        },
        {
          title: ''
        }
      ]
    }
  ]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [program, setProgram] = useState([]);
  const { fields, isFieldsLoading } = useFields();
  const [form, setForm] = useState();
  const { id } = useParams();
  const extraFields = ItemProgramFieldsCollapse(
    id,
    form,
    trainingFieldValue,
    setTrainingFieldValue,
    trainingContent,
    setTrainingContent,
    setSelectedSkills,
    selectedSkills,
    importDocs,
    setImportDocs,
    state
  );

  const config = {
    onGetResource: {
      setFields: (data) => {
        setProgram(data);
        setTrainingFieldValue(data.training_action_type?.training_domain);
        setSelectedSkills(data.skills);
        setTrainingContent(data.training_content);
        return data;
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        return {
          ...program,
          ...data,
          ...(data.educational_goals
            ? {
                educational_goals: data.educational_goals
              }
            : {}),
          ...(data.trainees_profile
            ? {
                ...data.trainees_profile,
                ...(data.trainees_profile.customers
                  ? {
                      customers: data.trainees_profile.customers
                    }
                  : {}),
                ...(data.trainees_profile.prerequisite
                  ? {
                      prerequisite: data.trainees_profile.prerequisite
                    }
                  : {})
              }
            : {}),
          ...(data.execution_track
            ? {
                execution_track: {
                  ...program.execution_track,
                  ...data.execution_track,
                  ...(data.execution_track.results_track
                    ? {
                        results_track: data.execution_track.results_track
                      }
                    : {}),

                  ...(data.execution_track.educational_resources
                    ? {
                        educational_resources:
                          data.execution_track.educational_resources
                      }
                    : {}),

                  ...(data.execution_track.team_description
                    ? data.execution_track.team_description
                    : '')
                }
              }
            : {}),

          ...(data.certifications_modality
            ? {
                certifications_modality: {
                  ...program.certifications_modality,
                  ...data.certifications_modality,
                  ...(data.certifications_modality.expected_results
                    ? data.certifications_modality.expected_results
                    : ''),
                  ...(data.certifications_modality.obtaining_modality
                    ? data.certifications_modality.obtaining_modality
                    : ''),
                  ...(data.certifications_modality.certification_details
                    ? data.certifications_modality.certification_details
                    : ''),
                  ...(data.certifications_modality.validity_duration
                    ? data.certifications_modality.validity_duration
                    : 0)
                }
              }
            : {}),
          ...(data.training_action_type
            ? {
                training_action_type: {
                  ...data.training_action_type,
                  training_domain: trainingFieldValue
                }
              }
            : {}),
          skills: selectedSkills,
          training_content: trainingContent
        };
      }
    }
  };
  return (
    <CreateUpdateContainer
      setForm={setForm}
      resource="programs"
      fields={fields}
      urlGoBack={
        state && state.session
          ? `${routes.SESSIONS}/show/${state.session._id}`
          : `${routes.CATALOG}${subRoutes.CATALOG.PROGRAMS}/show/${id}`
      }
      formExtra={extraFields}
      isParentLoading={isFieldsLoading}
      purpose={purpose}
      baseUrl="programs"
      config={config}
      withSubRoutes
      withConfirmationButton={!importDocs}
    />
  );
};

UpdateProgram.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default UpdateProgram;
