import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Drawer, Form, message, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useFields from './TraineesDrawerFields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Title } = Typography;

const NewTraineeDrawer = ({
  company,
  isVisible,
  setVisibilityTraineesDrawer,
  refresh,
  setRefresh,
  setId,
  id,
  trainees,
  sessionState
}) => {
  const { t } = useTranslation();
  const { notification } = ErrorStatusCode();
  const [form] = Form.useForm();
  const { dispatchAPI } = useAuthContext();
  const [companies, setCompanies] = useState([{}]);
  const { fields } = useFields(companies, 'drawerCompany');
  const generateFields = useGenerateFormItem();
  const [postTrainee, setPostTrainee] = useState(false);

  const cancelButton = () => {
    form.setFieldsValue({
      gender: '',
      first_name: '',
      last_name: '',
      study_level: '',
      study_name: '',
      email: '',
      number: '',
      street: '',
      additional: '',
      postcode: '',
      city: '',
      phone_number: '',
      status: ''
    });
    setVisibilityTraineesDrawer();
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    getCompanies();
  }, [refresh]);

  useEffect(() => {
    form.setFieldValue('company', company?._id);
  }, [company, refresh]);

  const createTrainee = async (body) => {
    const randomPassword = Math.random()
      .toString(36)
      .slice(-8);

    try {
      const res = await dispatchAPI('POST', {
        url: '/trainees',
        body: {
          ...body,
          role: 'guests:GUEST',
          verifiedEmail: false,
          password: randomPassword
        }
      });
      message.success(t('trainees.message.success.new'));
      return res.data;
    } catch (e) {
      message.error(t('trainees.message.error'));
    }
    return null;
  };

  const onFinish = async (values) => {
    let body = {
      ...values
    };
    if (company.length || Object.keys(company).length) {
      body = {
        ...values,
        company: company._id || company
      };
    }

    const data = await createTrainee(body);
    setRefresh(!refresh);
    form.setFieldsValue({
      gender: '',
      first_name: '',
      last_name: '',
      study_level: '',
      study_name: '',
      email: '',
      number: '',
      street: '',
      additional: '',
      postcode: '',
      city: '',
      phone_number: '',
      status: ''
    });
    setPostTrainee(true);
    if (sessionState) sessionState(data);
    setVisibilityTraineesDrawer();
  };

  useEffect(() => {
    if (postTrainee) {
      const trainee = trainees[trainees.length - 1];
      setId([...id, trainee?._id]);
    }
    setPostTrainee(false);
  }, [trainees]);

  const onFinishFailed = () => {
    message.error(t('trainees.message.error'));
  };

  return (
    <>
      <Drawer
        visible={isVisible}
        onClose={() => {
          cancelButton();
        }}
        width="30%"
        className="new-trainee-drawer"
      >
        <Col style={{ width: '100%' }}>
          <Title level={3}>{t(`trainees.form.title.create`)}</Title>
        </Col>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ width: '100%' }}
        >
          {fields.map((field) =>
            generateFields('trainees' || 'trainees', field)
          )}
          <Button
            type="add"
            htmlType="submit"
            style={{
              margin: '16px 0 0 10px',
              float: 'right'
            }}
          >
            {t('buttons.save')}
            <CheckOutlined />
          </Button>
          <Button
            onClick={cancelButton}
            style={{
              float: 'right',
              marginTop: 16
            }}
            type="link"
            danger
          >
            {`${t('buttons.cancel')}`}
            <CloseOutlined />
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

NewTraineeDrawer.propTypes = {
  setVisibilityTraineesDrawer: PropTypes.func.isRequired,
  setRefresh: PropTypes.func.isRequired,
  setId: PropTypes.func.isRequired,
  company: PropTypes.shape({
    _id: PropTypes.string,
    length: PropTypes.func
  }).isRequired,
  isVisible: PropTypes.bool.isRequired,
  refresh: PropTypes.bool.isRequired,
  id: PropTypes.arrayOf({}).isRequired,
  sessionState: PropTypes.func.isRequired,
  trainees: PropTypes.arrayOf({}).isRequired
};

export default NewTraineeDrawer;
