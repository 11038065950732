import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  const headers = [
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'gender',
      key: 'gender'
    },
    {
      label: 'status',
      key: 'status'
    },
    {
      label: 'company',
      key: 'company.name'
    },
    {
      label: 'function',
      key: 'function'
    },
    {
      label: 'study_level',
      key: 'study_level'
    },
    {
      label: 'study_name',
      key: 'study_name'
    },
    {
      label: 'number',
      key: 'number'
    },
    {
      label: 'street',
      key: 'street'
    },
    {
      label: 'additional',
      key: 'additional'
    },
    {
      label: 'postcode',
      key: 'postcode'
    },
    {
      label: 'city',
      key: 'city'
    },
    {
      label: 'organization',
      key: 'organization.name'
    }
  ];

  const columns = [
    {
      title: t('trainees.form.last_name'),
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('trainees.form.first_name'),
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('trainees.form.nb_sessions'),
      dataIndex: 'nb_sessions',
      sorter: true
    }
  ];

  return { columns, headers };
};

export default useColumns;
