import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Popconfirm, Divider, Row, Col } from 'antd';
import { useDrag } from 'react-dnd';
import {
  EyeOutlined,
  CloseOutlined,
  WarningOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useBoxContext from './SessionsContext';
import { routes } from '../../../utils/constants/routes';

const Card = styled.div`
  background-color: #fff;
  margin-top: 0px;
  margin-bottom: 10px;
  height: 150px;
  width: 96%;
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  padding: 10px;
  position: relative;

  a,
  .anticon:not(.anticon-close) {
    color: #7c7c7c;
  }

  .anticon:not(.anticon-close) {
    transition: all 0.3s;
    &:hover {
      font-size: 24px;
    }
  }
`;
const Close = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: var(--secondaryColor);
  font-size: 12px;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;

  .anticon-close {
    transition: all 0.3s;
    &:hover {
      font-size: 16px;
    }
  }
`;
const EditInfo = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
`;

const SessionCard = ({ session, color }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const { _id, name } = session;
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'card',
      id: _id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });
  const { fetchData, delSession, setVisible, setSession } = useBoxContext();

  const [visibility, setVisibility] = React.useState('hidden');
  const styles = {
    visibility: `${visibility}`
  };

  return (
    <Card
      ref={drag}
      style={{ opacity: isDragging ? '0.5' : '1' }}
      onMouseEnter={() => setVisibility('visible')}
      onMouseLeave={() => setVisibility('hidden')}
    >
      <Row>
        <Col span={2}>
          <div style={{ background: color, width: 4, height: 56 }} />
        </Col>
        <Col span={20}>
          <Row gutter={[0, 0]}>
            <Col span={24}>
              <div style={{ fontSize: 18 }}>{name}</div>
            </Col>
            <Col span={24}>
              <div style={{ fontSize: 14 }}>
                {`${moment(session.min_start_date).format(
                  'DD/MM/YYYY'
                )} - ${moment(session.max_end_date).format('DD/MM/YYYY')}`}
              </div>
            </Col>
            <Col span={24}>
              <div style={{ fontSize: 14 }}>
                {(session?.customers || []).map((el, index) => (
                  <span key={el?.customer?._id}>
                    {el?.customer?.name || ''}
                    {index < session?.customers?.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </div>
            </Col>
            <Col span={24}>
              <div style={{ fontSize: 14 }}>
                {`${session.total_rates.toFixed(2)} €`}
              </div>
            </Col>
          </Row>
        </Col>

        <Col span={2}>
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => {
              delSession(_id);
              fetchData();
            }}
            icon={<WarningOutlined />}
          >
            <Close style={styles}>
              <CloseOutlined />
            </Close>
          </Popconfirm>
        </Col>
        <EditInfo style={styles}>
          <EditOutlined
            onClick={() => {
              history.push(`${routes.SESSIONS}/edit/${session._id}`);
              setSession(_id);
              setVisible(true);
            }}
          />
          <Divider type="vertical" />
          <Link
            to={{
              pathname: `${pathname}/show/${_id}`
            }}
          >
            <EyeOutlined />
          </Link>
        </EditInfo>
      </Row>
    </Card>
  );
};

SessionCard.propTypes = {
  deal: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    lead: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    priority: PropTypes.string
  }).isRequired,
  color: PropTypes.string.isRequired,
  session: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    customers: PropTypes.arrayOf(PropTypes.shape({})),
    total_rates: PropTypes.number,
    min_start_date: PropTypes.string,
    max_end_date: PropTypes.string
  }).isRequired
};

export default SessionCard;
