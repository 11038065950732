import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Tabs,
  Popconfirm,
  Skeleton,
  Divider,
  message,
  Collapse,
  Alert,
  Row,
  Col
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import { routes } from '../../utils/constants/routes';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ModulesList from './sessionModule/ModulesList';
import SessionCustomersTable from './sessionCustomers/SessionCustomersTable';
import AssociatedProgramToSession from './sessionProgram/AssociatedProgramToSession';
import ProgramsTable from './sessionProgram/programsTable';
import SessionContributorsList from './sessionContributors/SessionContributorsList';
import GenerateConventions from './conventions/GenerateConventions/generateConventions';
import SessionPlanning from './SessionPlanning/SessionPlanning';
import Extranet from './Extranet/Extranet';
import Signings from './signings/Signings';
import GenerateConvocations from './Convocations/generateConvocations';
import Certifications from './certifications/Certifications';
import Emails from './Emails/Emails';
import Quizzes from './Quizzes/Quizzes';
import Invoices from './invoices/Invoices';
import Customers from './Customers/Customers';
import Quality from './Quality/Quality';
import ListSessionFiles from './conventions/ListSessionFiles';
import ModalUploadFiles from '../Catalog/Program/modalUploadFiles';
import ErrorStatusCode from '../../utils/ErrorStatusCode';
import SessionsSteps from './components/Steps';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const DetailsSession = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [session, setSession] = useState({});
  const [programs, setPrograms] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [emails, setEmails] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [contributors, setContributors] = useState([]);
  const [traineesList, setTraineesList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [quizzes, setQuizzes] = useState([]);
  const [quizzesResponses, setQuizzesResponses] = useState([]);
  const [modules, setModules] = useState([]);
  const [status, setStatus] = useState(null);
  const tabIndex = localStorage.getItem('tabIndex');
  const { notification } = ErrorStatusCode();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sessions/${id}?customer=${JSON.stringify([
          { path: 'program' },
          { path: 'contributors', populate: { path: 'contributor' } },
          { path: 'files', populate: { path: 'user' } },
          {
            path: 'customers',
            populate: [
              { path: 'customer' },
              { path: 'trainees_list' },
              { path: 'funders', populate: { path: 'funder' } }
            ]
          }
        ])}`
      });
      setSession(data);
      setCustomers(
        data.customers.map(({ customer, ...c }) => ({
          ...c,
          customer,
          key: customer._id
        }))
      );
      setContributors(
        (data.contributors || []).map(({ contributor, ...c }) => ({
          ...c,
          contributor,
          key: contributor ? contributor._id : 'unknown'
        }))
      );
      const sessionStatus = await dispatchAPI('GET', {
        url: `/sessions/status/${id}`
      });
      setStatus(sessionStatus.data);
    } catch (e) {
      if (e?.response?.data?.status_code === 403) history.push(routes.SESSIONS);
      if (e.response) {
        notification(e.response);
      }
    }
    setIsLoading(false);
  };

  const getContacts = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contacts`
      });
      setContacts(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const getModules = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/module?session=${id}`
      });
      setModules(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const getEmails = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/emails?session=${id}&populate=user`
      });
      setEmails(data);
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const updateSession = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/sessions/${id}`, body });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const getPrograms = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/programs`
      });
      setPrograms(
        data.map(({ _id, ...program }) => ({
          ...program,
          key: _id
        }))
      );
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const getQuizzes = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/quizzes' });
      setQuizzes(
        data.map((el) => ({
          ...el,
          key: el._id
        }))
      );
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const getQuizzesResponses = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quizzes_responses?quiz=${id}&populate=user`
      });
      setQuizzesResponses(data.filter((el) => el.user !== null));
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
      await getPrograms();
      await getModules();
      await getContacts();
      await getEmails();
      await getQuizzes();
      await getQuizzesResponses();
    })();
  }, [refresh]);

  useEffect(() => {
    const list = [];
    customers.forEach((customer) => {
      if (customer.ref === 'Trainees' && customer.status === 'ACCEPTED') {
        list.push(customer.customer);
      } else if (customer.ref === 'Company' && customer.status === 'ACCEPTED') {
        list.push(...customer.trainees_list);
      }
    });
    setTraineesList(list);
  }, [customers]);

  const deleteSession = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/sessions/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message.error(e.response);
    }
  };

  const deleteFile = async (fileId) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${fileId}`
      });
    } catch (e) {
      message.error(t('settings.errors.category_delete'));
    }
  };

  const updateProgram = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/programs/${session.program._id}`,
        body
      });
    } catch (e) {
      if (e.response) {
        notification(e.response);
      }
    }
  };

  const downloadFile = async (fileId, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${fileId}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const deleteFileFromProgram = async (fileId) => {
    const files = session.program.files.filter((el) => el._id !== fileId);
    await updateProgram({ files });
    await deleteFile(fileId);
    await fetchData();
  };

  const handleTabClick = (value) => {
    localStorage.setItem('tabIndex', value);
  };

  return (
    <>
      <PageHeaderCustom
        title={t('sessions.show.name', {
          name: session && session.name
        })}
        extra={
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <SessionsSteps status={status} />
            <div>
              <Link
                to={{
                  pathname: `${routes.SESSIONS}/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteSession}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </div>
          </div>
        }
      />

      <Tabs
        defaultActiveKey={tabIndex || 1}
        tabsPosition="left"
        onTabClick={(value) => handleTabClick(value)}
        className="session-tabs"
      >
        <TabPane tab={t('sessions.form.general')} key="1">
          <ContentCustom>
            <Skeleton loading={isLoading} active>
              <DescriptionList data={listContent(session || {})} translate />
            </Skeleton>
          </ContentCustom>
        </TabPane>
        <TabPane tab={t('sessions.form.traineesDates')} key="2">
          <Row style={{ padding: '0 20px' }}>
            <Col span={24}>
              <Alert
                showIcon
                type="info"
                message={t('sessions.messages.trainees_dates')}
              />
            </Col>
          </Row>
          {modules.length > 1 && (
            <>
              <Divider orientation="left">
                <span>{t('sessions.planning')}</span>
              </Divider>
              <ContentCustom>
                <Collapse accordion ghost className="session-planning-collapse">
                  <Panel key="1" header={<b>{t('sessions.planning')}</b>}>
                    <SessionPlanning modules={modules} />
                  </Panel>
                </Collapse>
              </ContentCustom>
              <Divider />
            </>
          )}
          <Divider orientation="left">
            <span>{t(`sessions.form.modulesList`)}</span>
          </Divider>
          <ModulesList
            contributors={session && session.contributors}
            id={id}
            refresh={refresh}
            setRefresh={setRefresh}
            customers={session && session?.customers && session.customers}
          />
          <Divider orientation="left">
            <span>{t(`sessions.form.prospects`)}</span>
          </Divider>
          <SessionCustomersTable
            modules={modules}
            session={session}
            customers={customers.filter((el) => el.status === 'NEW')}
            fetchData={fetchData}
            updateSession={updateSession}
            type="new"
            purpose="trainees"
            contacts={contacts}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </TabPane>
        <TabPane tab={t('sessions.form.progContrib')} key="3">
          <Row style={{ padding: '0 20px' }}>
            <Col span={24}>
              <Alert
                showIcon
                type="info"
                message={t('sessions.messages.program_contributor')}
              />
            </Col>
          </Row>
          <Divider orientation="left">
            <span>{t(`sessions.form.sessionProgram`)}</span>
          </Divider>
          <div>
            {session?.program ? (
              <AssociatedProgramToSession
                updateSession={updateSession}
                fetchData={fetchData}
                session={session}
              />
            ) : (
              <ProgramsTable
                fetchData={fetchData}
                updateSession={updateSession}
                programs={programs}
                session={session}
              />
            )}
          </div>
          <Divider orientation="left">
            <span>{t(`sessions.form.sessionContributors`)}</span>
          </Divider>
          <div>
            {session &&
              session.contributors &&
              session.contributors.length !== 0 && (
                <SessionContributorsList
                  updateSession={updateSession}
                  fetchData={fetchData}
                  session={session}
                  getModules={getModules}
                  modules={modules}
                  emails={emails}
                  forceRefresh={refresh}
                  setForceRefresh={setRefresh}
                />
              )}
          </div>

          <Link
            to={{
              pathname: `${routes.SESSIONS}/show/${id}/contributors/create`
            }}
          >
            <Button type="primary" style={{ float: 'right', margin: 20 }}>
              {t('sessions.form.button.contributor')}
            </Button>
          </Link>

          {session?.program && (
            <>
              <Divider orientation="left">
                <span>{t('programs.form.externFiles')}</span>
              </Divider>
              <ModalUploadFiles
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                id={session.program._id}
                sessionId={session._id}
                fetchData={fetchData}
                uploadType="programs"
              />
              <Button
                type="link"
                onClick={() => {
                  setIsModalVisible(!isModalVisible);
                }}
                style={{ margin: '0 0 16px 16px' }}
              >
                {t('programs.form.title.addFiles')}
              </Button>
              <ListSessionFiles
                files={session.program.files}
                downloadFile={downloadFile}
                deleteFileFromSession={deleteFileFromProgram}
              />
            </>
          )}
        </TabPane>
        <TabPane tab={t('sessions.form.conventions')} key="4">
          <GenerateConventions
            contacts={contacts}
            emails={emails}
            updateSession={updateSession}
            fetchData={fetchData}
            customersList={customers}
            session={session}
            setForceRefresh={setRefresh}
            forceRefresh={refresh}
          />
        </TabPane>
        <TabPane tab={t('Clients')} key="13">
          <Customers
            modules={modules}
            contacts={contacts}
            setRefresh={setRefresh}
            refresh={refresh}
            session={session}
            updateSession={updateSession}
            fetchData={fetchData}
            customers={customers}
          />
        </TabPane>
        <TabPane tab={t('sessions.form.extranet')} key="5">
          <Extranet
            customers={customers}
            trainees={traineesList}
            updateSession={updateSession}
            fetchData={fetchData}
            session={session}
          />
        </TabPane>
        <TabPane tab={t('Convocations')} key="6">
          <GenerateConvocations
            emails={emails}
            session={session}
            fetchData={fetchData}
            customersList={customers.filter((el) => el.status === 'ACCEPTED')}
            trainees={traineesList}
            setForceRefresh={setRefresh}
            forceRefresh={refresh}
          />
        </TabPane>
        <TabPane tab={t('Emails')} key="12">
          <Emails
            emails={emails}
            contacts={contacts}
            session={session}
            fetchData={fetchData}
            customers={session?.customers}
            trainees={traineesList}
            setForceRefresh={setRefresh}
            forceRefresh={refresh}
          />
        </TabPane>
        <TabPane tab={t('Emargements')} key="7">
          <Signings
            session={session}
            customers={customers.filter((el) => el.status === 'ACCEPTED')}
            contributors={contributors}
            trainees={traineesList}
            modules={modules}
            fetchData={fetchData}
            updateSession={updateSession}
            setForceRefresh={setRefresh}
            forceRefresh={refresh}
            setEmails={setEmails}
          />
        </TabPane>
        <TabPane tab={t('sessions.form.evaluations')} key="8">
          <Quizzes trainees={traineesList} session={session} />
        </TabPane>
        <TabPane tab={t('sessions.form.quality')} key="9">
          <Quality
            trainees={traineesList}
            quizzes={quizzes.filter(
              (el) =>
                el.session &&
                el.session === id &&
                (el.type === 'post_training_j' ||
                  el.type === 'post_training_month')
            )}
            quizzesResponses={quizzesResponses}
          />
        </TabPane>
        <TabPane tab={t('sessions.form.attestations')} key="10">
          <Certifications
            updateSession={updateSession}
            trainees={traineesList}
            emails={emails}
            fetchData={fetchData}
            session={session}
            setForceRefresh={setRefresh}
            forceRefresh={refresh}
          />
        </TabPane>
        <TabPane tab={t('sessions.form.finance')} key="11">
          <Invoices
            modules={modules}
            emails={emails}
            session={session}
            customers={customers.filter((el) => el.status === 'ACCEPTED')}
            fetchData={fetchData}
            setForceRefresh={setRefresh}
            forceRefresh={refresh}
            contacts={contacts}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

export default DetailsSession;
