import React, { useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Input,
  Popconfirm,
  Row,
  Switch,
  Alert
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  WarningOutlined,
  ArrowRightOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import useColumns from './columns';
import DataTable from '../../components/DataTable/Datatable';
import { routes } from '../../utils/constants/routes';
import useBoxContext from './SessionKanban/SessionsContext';
import useAuthContext from '../../contexts/AuthContext';
import CheckTrialDays from '../../utils/checkTrialDays';

const { Search } = Input;

const ListSessions = () => {
  const columns = useColumns();
  const { t } = useTranslation();
  const history = useHistory();
  const [fencedView, setFencedView] = useState(false);
  const {
    searchResource,
    searchValue,
    kanbanCols,
    delSession,
    dataRefresh
  } = useBoxContext();

  const { user } = useAuthContext();

  const actionColumn = [
    {
      key: 'action',
      render: ({ key }) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `${routes.SESSIONS}/show/${key}`
            }}
          >
            <EyeOutlined />
          </Link>
          <Divider type="vertical" />
          <Link
            to={{
              pathname: `${routes.SESSIONS}/edit/${key}`
            }}
          >
            <EditOutlined />
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            okButtonProps={{ type: 'danger' }}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => delSession(key)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined style={{ color: 'red' }} type="delete" />
          </Popconfirm>
        </div>
      )
    }
  ];
  return (
    <>
      <Row justify="space-between" gutter={[8, 16]}>
        <Row style={{ flexWrap: 'nowrap' }}>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={(value) => searchResource(value)}
            style={{ marginLeft: '-4px' }}
          />
          <Button
            type="primary"
            disabled={
              !CheckTrialDays(user) && !user.organization.current_subscription
            }
            onClick={() => history.push(`${routes.SESSIONS}/create`)}
          >
            {t('buttons.create')}
            <PlusOutlined />
          </Button>
        </Row>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Col>
            {CheckTrialDays(user) ? (
              <Alert
                showIcon
                message={<span>{t('sessions.messages.trial_period')}</span>}
                type="warning"
              />
            ) : (
              !user?.organization?.current_subscription && (
                <Alert
                  showIcon
                  message={
                    <span>
                      {user.organization.current_subscription
                        ? t('profile.minSubscription')
                        : t(`buttons.infos.subscriptions`)}
                      <Button
                        onClick={() => {
                          history.push('/profile');
                          localStorage.setItem('subscriptionIndex', '2');
                        }}
                        type="link"
                        icon={<ArrowRightOutlined />}
                      />
                    </span>
                  }
                  type="warning"
                />
              )
            )}
          </Col>
        </Row>
        <Col>
          <span
            style={{
              margin: 5,
              color: ' var(--primaryColor)',
              fontSize: 17
            }}
          >
            {t('sessions.show.archived')}
          </span>
          <Switch
            onChange={() => setFencedView(!fencedView)}
            checked={fencedView}
          />
        </Col>
      </Row>
      <DataTable
        resourceName="sessions"
        columns={[...columns, ...actionColumn]}
        searchValue={searchValue}
        forceRefresh={dataRefresh}
        extraQuery={
          fencedView &&
          `status=${kanbanCols.find((el) => el.label === 'ARCHIVEE')._id}`
        }
        populate="contributors.contributor,customers.customer,status"
        customActionColumn
      />
    </>
  );
};

export default ListSessions;
