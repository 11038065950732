import React, { useState } from 'react';
import {
  Space,
  Button,
  Card,
  Col,
  Divider,
  Progress,
  Row,
  Table,
  Tag,
  Tooltip,
  message,
  Alert,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EyeOutlined, SendOutlined } from '@ant-design/icons';
import DetailResponses from './detailsResponses';
import IndividualResponsesDetails from './IndividualResponsesDetails';
import useAuthContext from '../../../contexts/AuthContext';

const DistributeAndShowResponsesRate = ({
  setIsVisible,
  isVisible,
  quizzesResponses,
  percent,
  traineesList,
  quiz,
  setUsers,
  users,
  refresh,
  setRefresh,
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [selectedTrainee, setSelectedTrainee] = useState('');

  const checkIfResponded = traineeId => {
    let check = false;
    quizzesResponses.forEach(response => {
      if (traineeId === response.user?._id) {
        check = true;
      }
    });
    return check;
  };

  const showDetails = traineeId => {
    setSelectedTrainee(traineeId);
    setIsDetailsModalVisible(!isDetailsModalVisible);
  };

  const columns = [
    {
      title: 'Stagiaire',
      render: trainee => `${trainee.last_name} ${trainee.first_name}`,
    },
    {
      title: 'Tag',
      render: trainee =>
        checkIfResponded(trainee._id) ? (
          <Tag color="green">Répondu</Tag>
        ) : (
          <Tag color="red">Sans réponse</Tag>
        ),
    },
    {
      render: trainee => (
        <>
          {checkIfResponded(trainee._id) ? (
            <Tooltip overlay="Voir les réponses">
              <Button
                onClick={() => showDetails(trainee._id)}
                type="link"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          ) : (
            <Tooltip overlay="Envoyer un mail de relance">
              <Button
                onClick={() => sendMailToAll('one', trainee._id)}
                type="link"
                icon={<SendOutlined />}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  const sendMailToAll = (purpose, id) => {
    if (purpose === 'all') {
      setUsers(
        traineesList.map(el => ({
          ...el,
          ref: 'Trainees',
        }))
      );
    } else {
      setUsers(
        traineesList
          .filter(el => el._id === id)
          .map(el => ({
            ...el,
            ref: 'Trainees',
          }))
      );
    }
    setIsVisible(!isVisible);
  };

  const updateQuiz = async body => {
    try {
      await dispatchAPI('PATCH', { url: `/quizzes/${id}`, body });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const beginQuiz = async () => {
    await updateQuiz({ visibility: true });
    setRefresh(!refresh);
  };

  const stopQuiz = async () => {
    await updateQuiz({ visibility: false });
    setRefresh(!refresh);
  };

  const resentMail = () => {
    const list = [];
    traineesList.forEach(trainee => {
      quizzesResponses.forEach(response => {
        if (trainee._id !== response.user._id) {
          list.push(trainee);
        }
      });
    });
    setUsers(list);
    setIsVisible(!isVisible);
  };
  const redirect = url => {
    window.open(url, '_blank');
  };

  return (
    <>
      <IndividualResponsesDetails
        setIsVisible={setIsDetailsModalVisible}
        isVisible={isDetailsModalVisible}
        response={quizzesResponses.filter(
          el => el.user?._id === selectedTrainee
        )}
        quiz={quiz}
      />
      {quiz.type === 'training_quiz' ? (
        <>
          <Divider orientation="left">
            <span style={{ fontSize: 25 }}>
              {t(`Démarrer l'évaluation de fin de formation`)}
            </span>
          </Divider>
          <Card>
            <Space>
              {quiz.visibility ? (
                <>
                  <Button type="danger" onClick={() => stopQuiz()}>
                    Stopper l'activité
                  </Button>
                  <Button
                    type="link"
                    onClick={() =>
                      redirect(
                        `${process.env.REACT_APP_API_URL}/redirect/${quiz.session}?userId=${id}`
                      )
                    }>
                    Rejoindre l'activité
                  </Button>
                </>
              ) : (
                <>
                  <Button type="primary" onClick={() => beginQuiz()}>
                    Démarrer l'activité en présentiel
                  </Button>
                </>
              )}
              <Button type="link" onClick={() => sendMailToAll('all')}>
                Rappeler le lien de l'extranet par mail aux stagiaires
              </Button>
            </Space>
          </Card>
        </>
      ) : (
        <>
          <Divider orientation="left">
            <span style={{ fontSize: 25 }}>
              {t(`Distribuer le formulaire`)}
            </span>
          </Divider>
          <Card>
            <Row>
              <Button
                disabled={!quiz?.questions?.length}
                type="link"
                onClick={() => sendMailToAll('all')}
              >
                Envoyer un lien vers le formulaire aux stagiaires
              </Button>
              {!quiz?.questions?.length && (
                <Alert
                  message="la liste des questions est vide"
                  type="warning"
                  showIcon
                />
              )}
            </Row>
            {quizzesResponses.length < traineesList.length &&
              quizzesResponses.length > 0 && (
                <Row>
                  <Button type="link" onClick={() => resentMail()}>
                    Envoyer un mail de relance aux stagiaires qui n'ont pas
                    répondu au formulaire
                  </Button>
                </Row>
              )}
          </Card>
        </>
      )}

      {quizzesResponses.filter(el => el.quiz === id).length !== 0 && (
        <>
          <Divider orientation="left">
            <span>{t(`Taux de réponses`)}</span>
          </Divider>
          <Card>
            <Row>
              <Col offset={25} span={8}>
                <Row>
                  <Col span={12}>
                    <span style={{ fontSize: 15 }}>Réponses: </span>
                  </Col>
                  <Col span={4}>
                    <span style={{ fontSize: 15 }}>
                      {quizzesResponses.filter(el => el.quiz === id).length}/
                      {traineesList.length}
                    </span>
                  </Col>
                </Row>
              </Col>
              <Col offset={5} span={4}>
                <Progress width={80} type="circle" percent={percent} />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={24}>
                <Table columns={columns} dataSource={traineesList} />
              </Col>
            </Row>
          </Card>
          <Divider orientation="left">
            <span>{t(`Statistiques des réponses`)}</span>
          </Divider>
          <Card>
            <DetailResponses
              traineesList={traineesList}
              responses={quizzesResponses}
              quiz={quiz}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default DistributeAndShowResponsesRate;
