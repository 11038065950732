import moment from 'moment';

const calculateRateIfIsPriceByCustomerType = (
  rate,
  customerRef,
  customerStatus,
  nbDays,
  nbHours,
  nbTrainees
) => {
  let priceByCustomerAmount = 0;
  let priceByCustomerMargeAmount = 0;

  const getRateByCustomerType = (rate, customerRef, customerStatus) => {
    switch (customerRef) {
      case 'Company':
        return rate.company_rate;
      case 'Trainees':
        return customerStatus === 'PRIVATE'
          ? rate.private_rate
          : rate.independent_rate;
      default:
        return rate.independent_rate;
    }
  };

  const calculateAmount = (rate, multiplier) => {
    return rate * multiplier * (1 + rate.tva / 100);
  };

  const getMultiplierByRateType = (rate, nbDays, nbHours, nbTrainees) => {
    switch (rate.customer_type) {
      case 'CUSTOMER':
        return 1;
      case 'DAY':
        return nbDays;
      case 'HOUR':
        return nbHours;
      case 'TRAINEE':
        return nbTrainees || 1;
      case 'TRAINEE_DAY':
        return (nbTrainees || 1) * nbDays;
      case 'TRAINEE_HOUR':
        return (nbTrainees || 1) * nbHours;
      default:
        return 1;
    }
  };

  priceByCustomerAmount = calculateAmount(
    getRateByCustomerType(rate, customerRef, customerStatus),
    getMultiplierByRateType(rate, nbDays, nbHours, nbTrainees)
  );
  priceByCustomerMargeAmount = rate.rate;

  return { priceByCustomerAmount, priceByCustomerMargeAmount };
};

const getNbDaysFromSlotsModule = (slots) => {
  return slots.date.filter((el) => el.sub_slots.length).length;
};

const getNbHoursFromSlotsModule = (slots) => {
  let nbHour = 0;
  slots.date.forEach((date) => {
    date.sub_slots
      .filter((el) => el)
      .forEach((subSlot) => {
        const endTime = moment(subSlot.end_time, 'HH:mm');
        const startTime = moment(subSlot.start_time, 'HH:mm');
        nbHour += (endTime - startTime) / 3600000;
      });
  });
  return nbHour;
};

const checkIfIsPriceByCustomerType = (rateItem) => {
  return !!rateItem.company_rate;
};

const getNbTrainees = (trainees) => {
  return trainees ? trainees.length : 0;
};

const switchRateType = (
  rates,
  nbDays,
  nbHours,
  customerTraineesList,
  customerRef,
  customerStatus
) => {
  let typeAmount = 0;
  let typeMargeAmount = 0;
  const nbTrainees = getNbTrainees(customerTraineesList);

  rates.forEach((rate) => {
    if (!checkIfIsPriceByCustomerType(rate)) {
      typeAmount += rate.rate * (1 + rate.tva / 100);
      typeMargeAmount += rate.rate;
    } else {
      const {
        priceByCustomerAmount,
        priceByCustomerMargeAmount
      } = calculateRateIfIsPriceByCustomerType(
        rate,
        customerRef,
        customerStatus,
        nbDays,
        nbHours,
        nbTrainees
      );

      typeAmount += priceByCustomerAmount;
      typeMargeAmount += priceByCustomerMargeAmount;
    }
  });

  return [typeAmount, typeMargeAmount];
};

const CalculRateModule = (module, customers) => {
  let totalAmount = 0;
  let margeAmount = 0;

  const nbDays = getNbDaysFromSlotsModule(module.slots);
  const nbHours = getNbHoursFromSlotsModule(module.slots);

  customers.forEach((customer) => {
    let amount = 0;
    let typeAmount = 0;
    let typeMargeAmount = 0;

    if (customer.spec_rate && customer.spec_rate.length) {
      [typeAmount, typeMargeAmount] = switchRateType(
        customer.spec_rate,
        nbDays,
        nbHours,
        customer.trainees_list
      );
    } else {
      [typeAmount, typeMargeAmount] = switchRateType(
        module.rates,
        nbDays,
        nbHours,
        customer.trainees_list,
        customer.ref,
        customer.customer && customer.customer.status
      );
    }

    amount = typeAmount;
    margeAmount += typeMargeAmount;
    totalAmount += amount;
  });

  return [totalAmount, margeAmount];
};

export default CalculRateModule;
