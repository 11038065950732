import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, List, Popconfirm, message, Progress, Alert, Spin } from 'antd';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { routes, subRoutes } from '../../../utils/constants/routes';
import useAuthContext from '../../../contexts/AuthContext';
import {
  ColdQuizIcon,
  ContributorQuizIcon,
  FounderQuizIcon,
  HotQuizIcon,
  ManagerQuizIcon,
  PreTrainingQuizIcon,
  EndTrainingQuizIcon
} from '../../../utils/constants/customIcon';

const getAvatarIcon = (type) => {
  switch (type) {
    case 'training_quiz' || 'training_quiz_model':
      return <EndTrainingQuizIcon />;
    case 'pre_training' || 'pre_training_model':
      return <PreTrainingQuizIcon />;
    case 'post_training_j' || 'post_training_j_model':
      return <HotQuizIcon />;
    case 'post_training_month' || 'post_training_month_model':
      return <ColdQuizIcon />;
    case 'managers_quizzes' || 'managers_quizzes_model':
      return <ManagerQuizIcon />;
    case 'contributors_quizzes' || 'contributors_quizzes_model':
      return <ContributorQuizIcon />;
    case 'funders_quizzes' || 'funders_quizzes_model':
      return <FounderQuizIcon />;
    default:
      return null;
  }
};

const ListQuizzes = ({
  trainees,
  quizzesResponse,
  quizzes,
  refresh,
  setRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { id } = useParams();
  const { pathname } = useLocation();
  const urlSplit = pathname.split('/');
  const loc = urlSplit[1];
  const [link, setLink] = useState('');
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (loc !== 'catalog') {
      setLink(`${routes.SESSIONS}/show/${id}/quizzes`);
    } else {
      setLink(`${routes.CATALOG}${subRoutes.CATALOG.QUIZZES_MODELS}/show`);
    }
  }, []);

  const deleteQuiz = async (idQuiz) => {
    try {
      setIsFieldsLoading(true);
      if (!quizzesResponse.some((object) => object.quiz === idQuiz)) {
        await dispatchAPI('DELETE', { url: `/quizzes/${idQuiz}` });
        setRefresh(!refresh);
      } else {
        message.error(t('sessions.quizzes.messages.has_responses'));
      }
      setIsFieldsLoading(false);
    } catch (e) {
      if (e.response) message.error(e.response);
    }
  };

  const calculateNbQuizResponse = (quizId) => {
    const quizResponses =
      quizzesResponse && quizzesResponse.filter((el) => el.quiz === quizId);
    return (
      <>
        <span>{t('sessions.quizzes.show.responses')}</span>
        <span>
          {`${(quizResponses || []).length} / ${(trainees || []).length}`}
        </span>
        <Progress
          percent={(
            ((quizResponses || []).length * 100) /
            (trainees || []).length
          ).toFixed(2)}
        />
      </>
    );
  };

  return (
    <>
      {quizzes.length !== 0 && (
        <Spin size="large" spinning={isFieldsLoading}>
          <List
            dataSource={quizzes}
            renderItem={(item) => (
              <List.Item
                actions={[
                  !item.questions.length && (
                    <Alert
                      message="la liste des questions est vide"
                      type="warning"
                      showIcon
                    />
                  ),
                  loc !== 'catalog' && calculateNbQuizResponse(item._id),
                  <Link
                    to={{
                      pathname: `${link}/${item.key}`
                    }}
                  >
                    <EditOutlined />
                  </Link>,
                  <Popconfirm
                    title={t('datatable.column.action.delete.title')}
                    okText={t('datatable.column.action.delete.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('datatable.column.action.delete.cancel')}
                    onConfirm={async () => {
                      await deleteQuiz(item.key);
                    }}
                    icon={<WarningOutlined />}
                  >
                    <DeleteOutlined style={{ color: 'red' }} type="delete" />
                  </Popconfirm>
                ]}
              >
                <List.Item.Meta
                  onDoubleClick={() => history.push(`${link}/${item.key}`)}
                  avatar={
                    <Avatar
                      className="quiz-type-avatar"
                      icon={getAvatarIcon(item.type)}
                    />
                  }
                  title={item.name}
                />
              </List.Item>
            )}
          />
        </Spin>
      )}
    </>
  );
};

ListQuizzes.propTypes = {
  trainees: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  quizzesResponse: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  quizzes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired
};

export default ListQuizzes;
