import React, { useEffect, useState } from 'react';
import {
  Divider,
  Button,
  Collapse,
  List,
  message,
  PageHeader,
  Space,
  Dropdown,
  Card,
  Menu,
  Popconfirm,
  Tag,
  Tooltip
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  DownOutlined,
  CheckOutlined,
  DownloadOutlined,
  SendOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../utils/constants/routes';
import useAuthContext from '../../../contexts/AuthContext';
import ModalGeneratingFile from '../Convocations/modalGeneratingFile';
import ModalSendMails from '../Convocations/modalSendMails';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const SessionContributorsList = ({
  session,
  updateSession,
  modules,
  emails,
  fetchData,
  getModules,
  forceRefresh,
  setForceRefresh
}) => {
  const { t } = useTranslation();

  const [file, setFile] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const [templates, setTemplates] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalGenerateFileVisible, setIsModalGenerateFileVisible] = useState(
    false
  );
  const [users, setUsers] = useState([]);
  const [sendType, setSendType] = useState('one');
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [generatedFile, setGeneratedFile] = useState({});
  const [exist, setExist] = useState({});
  const [emailModel, setEmailModel] = useState('');
  const [isContributor, setIsContributor] = useState({});
  const { notification } = ErrorStatusCode();

  const getFile = () => {
    try {
      const list = user.organization.templates.filter(
        (el) => el.type === 'subcontracting'
      );
      setTemplates(list);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  useEffect(() => {
    (async () => {
      await getFile();
    })();
  }, []);

  const generateFile = async (temp, customId, fileType) => {
    try {
      setLoading(true);
      message.info(
        'Veuillez patienter, le document est en cours de génération',
        5
      );
      await dispatchAPI('GET', {
        url: `/files/generate/subcontracting/${session._id}/${customId}/${temp._id}?fileType=${fileType}`,
        responseType: 'blob'
      });
      message.success('Document généré avec succès');
      setIsModalGenerateFileVisible(true);
      await fetchData();
    } catch (e) {
      if (e.response) notification(e.response);
    }
    setLoading(false);
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const showModal = (purpose, id, file) => {
    setEmailModel(purpose);
    setUsers(
      session.contributors
        .map(({ contributor }) => ({
          ...contributor,
          id: contributor._id,
          ref: 'Contributor'
        }))
        .filter((contri) => contri.id === id)
    );

    setFile([file]);
    setIsModalVisible(!isModalVisible);
  };
  const listActions = (type, id, ref, idItem) => {
    let actions = [];
    session.files.forEach((file) => {
      if (
        file.user?._id &&
        id &&
        file.user?._id.toString() === id.toString() &&
        file.type === type
      ) {
        actions = [
          <>
            <Tag style={{ fontSize: 15 }} color="orange">
              <span>
                <CheckOutlined
                  style={{ margin: '4px 2px 0 0', fontSize: 14 }}
                />
                {`Générée le ${moment(file.date).format('DD-MM-YYYY')} `}
              </span>
              <span>{`au format ${file.filename.split('.')[1]}`}</span>
            </Tag>
          </>
        ];
      }
    });

    const email = emails.find(
      (el) =>
        el.user?._id &&
        id &&
        el.user._id.toString() === id.toString() &&
        el.purpose === 'subcontracting_contributor'
    );
    if (email) {
      actions.push(
        <Tag color="green">
          <span style={{ fontSize: 15 }}>
            <CheckOutlined style={{ margin: '4px 2px 0 0', fontSize: 14 }} />
            Envoyée
          </span>
        </Tag>
      );
    }
    return actions;
  };

  const deleteContributorFromSession = async (idContributor) => {
    const oldContributorsList = session.contributors;
    const contributorsList = oldContributorsList.filter(
      (el) => el.contributor?._id !== idContributor
    );
    const body = contributorsList.map((c) => ({
      ...c,
      contributor: c.contributor._id
    }));
    await updateSession({ contributors: body });

    await Promise.all(
      modules.map(async (module, indexModule) => {
        if (module.slots) {
          const date = module.slots.date.map((date, indexDate) => ({
            ...date,
            sub_slots: date.sub_slots.map((subSlot, indexSub) => ({
              ...subSlot,
              contributors: subSlot.contributors.filter(
                (el) => el !== idContributor
              )
            }))
          }));
          return await dispatchAPI('PATCH', {
            url: `/module/${module._id}`,
            body: { slots: { ...module.slots, date } }
          });
        }
      })
    );
    await fetchData();
    await getModules();
  };

  useEffect(() => {
    const exists = {};
    const filesList = {};
    session.contributors.forEach(({ contributor }) => {
      const file = session.files
        .filter(
          (file) =>
            file.user &&
            file.user._id.toString() === contributor?._id.toString() &&
            file.type === 'subcontracting'
        )
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];
      if (file) {
        exists[contributor._id] = true;
        filesList[contributor._id] = {
          fileName: file.filename,
          id: file._id
        };
      }
    });
    setExist(exists);
    setGeneratedFile(filesList);
  }, [session.files]);

  useEffect(() => {
    const list = {};
    session.contributors.forEach(({ contributor }) => {
      const sessionContributor = modules.map((module) =>
        module.slots.date
          .map(
            (date) =>
              date &&
              date.sub_slots &&
              date.sub_slots.map((el) =>
                el.contributors.filter(
                  (ele) => ele.toString() === contributor?._id.toString()
                )
              )
          )
          .filter((date) => date && date.length)
      );

      if (!sessionContributor.flat(2).length && contributor?._id) {
        list[`${contributor?._id}-subcontracting`] = true;
      }
    });
    setIsContributor(list);
  }, [session.contributors, modules]);

  const menuDropDown = (id) => {
    setCustomerId(id);
    const menu = (
      <Menu>
        {templates.map((temp) => (
          <>
            <Menu.Item key={`${temp._id}_docx`}>
              {loading ? (
                <Button disabled type="link">
                  {`${temp.name.split('.')[0]} docx`}
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => generateFile(temp, id, 'docx')}
                >
                  {`${temp.name.split('.')[0]} docx`}
                </Button>
              )}
            </Menu.Item>
            <Menu.Item key={`${temp._id}_docx`}>
              {loading ? (
                <Button disabled type="link">
                  {`${temp.name.split('.')[0]} pdf`}
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => generateFile(temp, id, 'pdf')}
                >
                  {`${temp.name.split('.')[0]} pdf`}
                </Button>
              )}
            </Menu.Item>
          </>
        ))}
      </Menu>
    );

    return menu;
  };

  return (
    <>
      <div className="site-card_border-less-wrapper">
        <ModalSendMails
          emailModel={emailModel}
          files={file}
          sendType={sendType}
          file={file}
          users={users}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          emailPurpose="subcontracting_contributor"
          refresh={forceRefresh}
          setRefresh={setForceRefresh}
          session={session}
        />
        <ModalGeneratingFile
          isVisible={isModalGenerateFileVisible}
          setVisible={setIsModalGenerateFileVisible}
        />
        <Card>
          <List
            size="small"
            itemLayout="horizontal"
            dataSource={session.contributors}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    item.contributor &&
                    `${item.contributor?.last_name} ${item.contributor?.first_name}`
                  }
                />
                <span style={{ float: 'right' }}>
                  {listActions('subcontracting', item.contributor?._id)}
                  {loading && item.contributor?._id === customerId ? (
                    <LoadingOutlined />
                  ) : (
                    <></>
                  )}
                  {item.status === 'EXTERNAL' ? (
                    <Dropdown
                      overlay={() => menuDropDown(item.contributor?._id)}
                      trigger={['click']}
                      disabled={
                        isContributor[`${item.contributor?._id}-subcontracting`]
                      }
                    >
                      {isContributor[
                        `${item.contributor?._id}-subcontracting`
                      ] ? (
                        <Tooltip title="Veuillez associer des dates dans les crénaux de l'intervenant du module de formation.">
                          <Link
                            to={{
                              pathname: `${routes.SESSIONS}/show/${session._id}/contributors/edit/${item.contributor?._id}`
                            }}
                          >
                            <Button type="link">
                              contrat de sous-traitance <DownOutlined />{' '}
                            </Button>
                          </Link>
                        </Tooltip>
                      ) : (
                        <Button type="link">
                          contrat de sous-traitance <DownOutlined />{' '}
                        </Button>
                      )}
                    </Dropdown>
                  ) : (
                    <>
                      <Button
                        type="link"
                        icon={<SendOutlined />}
                        onClick={() => {
                          setSendType('one');
                          showModal(
                            'contributors_email_model',
                            item.contributor?._id
                          );
                        }}
                      />
                      <Divider type="vertical" />
                    </>
                  )}
                  {exist[item.contributor?._id] && (
                    <>
                      <Divider type="vertical" />
                      <Button
                        type="link"
                        icon={<DownloadOutlined />}
                        onClick={() =>
                          downloadFile(
                            generatedFile[item.contributor?._id].id,
                            generatedFile[item.contributor?._id].fileName
                          )
                        }
                      />
                      <Divider type="vertical" />
                      <Button
                        type="link"
                        icon={<SendOutlined />}
                        onClick={() => {
                          setSendType('one');
                          showModal(
                            'subcontracting_contributor_model',
                            item.contributor?._id,
                            generatedFile[item.contributor?._id]
                          );
                        }}
                      />
                      <Divider type="vertical" />
                    </>
                  )}
                  <Link
                    to={{
                      pathname: `${routes.SESSIONS}/show/${session._id}/contributors/edit/${item.contributor?._id}`
                    }}
                  >
                    <EditOutlined />
                  </Link>
                  <Divider type="vertical" />
                  <Popconfirm
                    title={t('datatable.column.action.delete.title')}
                    okText={t('datatable.column.action.delete.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('datatable.column.action.delete.cancel')}
                    onConfirm={async () => {
                      await deleteContributorFromSession(item.contributor?._id);
                    }}
                    icon={<WarningOutlined />}
                  >
                    <DeleteOutlined style={{ color: 'red' }} type="delete" />
                  </Popconfirm>
                </span>
              </List.Item>
            )}
          />
        </Card>
      </div>
    </>
  );
};

export default SessionContributorsList;
