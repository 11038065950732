import React, { useEffect } from 'react';
import { Tree } from 'antd';

const SlotsToContributorsSession = ({
  slots,
  form,
  expandedKeys,
  setExpandedKeys,
  checkedKeys,
  setCheckedKeys,
  selectedKeys,
  setSelectedKeys,
  slotsContributor,
  setSlotsContributor,
  idContributor,
  selectedModules,
  setSelectedModules,
  modules
}) => {

  const onExpand = expandedKeysValue => {
    setExpandedKeys(expandedKeysValue);
  };

  const onCheck = checkedKeysValue => {
    setCheckedKeys(checkedKeysValue);
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  useEffect(() => {
    (async () => {
      if (checkedKeys.length) {
        const tmp = []
        modules.forEach(mod => {
          if (checkedKeys.includes(mod._id) && !tmp.map(el => el._id).includes(mod._id)) {
            const module = modules.find(el => el._id === mod._id)
            tmp.push(module);
          } else {
            mod.slots.date.forEach(dt => {
              if (checkedKeys.includes(dt._id) && !tmp.map(el => el._id).includes(mod._id)) {
                const module = modules.find(el => el._id === mod._id)
                tmp.push(module);
              } else {
                dt.sub_slots.forEach(subSlot => {
                  if (checkedKeys.includes(subSlot._id)&& !tmp.map(el => el._id).includes(mod._id)) {
                    const module = modules.find(el => el._id === mod._id)
                    tmp.push(module);
                  }
                });
              };
            });
          };
        });
        setSelectedModules(tmp);
      };
    })();
  }, [checkedKeys]);

  useEffect(() => {
    if (slots.length && idContributor) {
      const slotsContri = [];
      slots.forEach(mod => {
        mod.children.forEach(slot => {
          slot.children.forEach(subSlot => {
            if (
              subSlot.contributors.find(
                contri => contri._id.toString() === idContributor.toString()
              )  && !slotsContri.includes(subSlot._id)
            ) {
              slotsContri.push(subSlot.key);
            }
          });
        });
      });
      setCheckedKeys(slotsContri);
    }
  }, [slots, idContributor]);

  useEffect(() => {
    if (slots.length) {
      setExpandedKeys(slots.map(keys => keys.key));
    }
  }, [slots]);

  return (
    <div>
      <Tree
        checkable
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        onCheck={onCheck}
        onSelect={onSelect}
        checkedKeys={checkedKeys}
        selectedKeys={selectedKeys}
        treeData={slots}
      />
    </div>
  );
};

export default SlotsToContributorsSession;
