import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Line } from '@ant-design/charts';
import { Col, Row, Statistic } from 'antd';
import moment from 'moment';

const Quote = ({ sessionData }) => {
  const [data, setData] = useState([]);
  const [dataPaye, setDataPaye] = useState([]);
  const [montant, setMontant] = useState(0);
  const [dataRefus, setDataRefus] = useState([]);
  const [dataWaiting, setDataWaiting] = useState([]);
  const [devis, setDevis] = useState([]);
  const [nbDevisEmis, setNbDevisEmis] = useState(0);
  const [nbDevisWaiting, setNbDevisWaiting] = useState(0);
  const [nbDevisPaye, setNbDevisPaye] = useState(0);
  const [nbDevisRefus, setNbDevisRefus] = useState(0);

  const getSessions = async () => {
    let res = [];
    sessionData.forEach((el) => {
      el.files.forEach((file) => {
        if (file.type === 'quotations') {
          res.push({ ...file, session: el._id });
        }
      });
    });
    res = res.map((el) => ({
      ...el,
      month: moment(el.date)
        .format('DD-MM-YYYY')
        .split('-')[1],
      type: moment(el.date)
        .format('DD-MM-YYYY')
        .split('-')[2]
    }));
    setDevis(res);
  };

  const getDefaultData = (year) => {
    const date = [];
    for (let i = 1; i < 13; i++) {
      date.push({
        month: `${i}`,
        type: year,
        value: 0
      });
    }
    return date;
  };
  useEffect(() => {
    const year = [];
    let newDataEmis = [];
    let newDataPaye = [];
    let newDataRefuse = [];
    let newDataWaiting = [];

    let nombreEmis = 0;
    let nombrePaye = 0;
    let nombreRefus = 0;
    let nombreWaiting = 0;

    let amount = 0;

    devis.forEach((dev) => {
      if (!year.includes(dev.type)) {
        year.push(dev.type);
        newDataEmis = [...newDataEmis, ...getDefaultData(dev.type)];
        newDataPaye = [...newDataPaye, ...getDefaultData(dev.type)];
        newDataRefuse = [...newDataRefuse, ...getDefaultData(dev.type)];
        newDataWaiting = [...newDataWaiting, ...getDefaultData(dev.type)];
      }
    });
    devis.forEach((el) => {
      newDataEmis.forEach((nd, index) => {
        if (
          nd.type === el.type &&
          (nd.month === el.month || `0${nd.month}` === el.month)
        ) {
          nombreEmis++;
          newDataEmis[index].value = newDataEmis[index].value + 1;

          if (el.status === 'accepted') {
            nombrePaye++;
            newDataPaye[index].value = newDataPaye[index].value + 1;
          } else if (el.status === 'waiting') {
            nombreWaiting++;
            amount += el.amount || 0;
            for (let i = index; i < newDataEmis.length; i++) {
              newDataWaiting[i].value =
                newDataWaiting[i].value + (el.amount || 0);
            }
          } else {
            nombreRefus++;
            newDataRefuse[index].value = newDataRefuse[index].value + 1;
          }
        }
      });
    });
    setMontant(amount);
    setNbDevisWaiting(nombreWaiting);
    setNbDevisEmis(nombreEmis);
    setNbDevisPaye(nombrePaye);
    setNbDevisRefus(nombreRefus);
    setDataPaye(newDataPaye);
    setDataRefus(newDataRefuse);
    setDataWaiting(newDataWaiting);
    setData(newDataEmis);
  }, [devis]);

  useEffect(() => {
    if (!sessionData) return;
    getSessions();
  }, [sessionData]);

  const config = {
    xField: 'month',
    yField: 'value',
    seriesField: 'type',
    color: function color(_ref) {
      const type = _ref.type;
      return type === '2021'
        ? '#F76B00'
        : type === '2020'
        ? '#4F71A8'
        : '#2b2b2b';
    },
    lineStyle: function lineStyle(_ref2) {
      const type = _ref2.type;
      if (type === '2020') {
        return {
          lineDash: [4, 4],
          opacity: 1
        };
      }
      return { opacity: 0.5 };
    },
    height: 340
  };

  return (
    <>
      <Row>
        <Col span={12}>
          <Statistic
            title="Nombre de devis en attente"
            value={nbDevisWaiting}
          />
        </Col>
        <Col span={12}>
          <Statistic title="Montant potentiel" suffix="€" value={montant} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Line {...{ ...config, data: dataWaiting }} />
        </Col>
      </Row>
    </>
  );
};

Quote.propTypes = {
  sessionData: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default Quote;
