import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Drawer,
  Form,
  message,
  Select,
  Typography,
  Row
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import useAuthContext from '../../../contexts/AuthContext';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import { ButtonAdd } from '../../../utils/constants/customButton';
import ErrorStatusCode from '../../../utils/ErrorStatusCode';

const { Title } = Typography;

const NewContributorDrawer = ({
  isVisible,
  setVisibilityContributorDrawer,
  refresh,
  setRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { fields, isFieldsLoading } = useFields();
  const [visibilitySkillDrawer, setVisibilitySkillDrawer] = useState(false);
  const [skills, setSkills] = useState([]);
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const cancelButton = () => {
    setVisibilityContributorDrawer();
  };
  const { notification } = ErrorStatusCode();

  const getSkills = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/skills' });
      setSkills(data);
    } catch (e) {
      if (e.response) notification(e.response);
    }
  };

  const createContributors = async (body) => {
    const randomPassword = Math.random()
      .toString(36)
      .slice(-8);
    try {
      await dispatchAPI('POST', {
        url: '/contributors',
        body: {
          ...body,
          role: 'guests:GUEST',
          verifiedEmail: false,
          password: randomPassword
        }
      });
      message.success(t('sessions.form.success.createContributor'));
      form.resetFields();
      setVisibilityContributorDrawer();
    } catch (e) {
      message.error(t('trainees.message.error'));
    }
  };

  const onSelectSkill = (value) => {
    setSelectedSkills([...selectedSkills, value]);
  };

  const onDeselect = (value) => {
    setSelectedSkills(selectedSkills.filter((sk) => sk !== value));
  };

  const onFinish = async (values) => {
    form.setFieldsValue({
      skills: selectedSkills,
      skills_notes: values.skills_notes,
      notes: values.notes
    });
    const body = { ...values };
    await createContributors(body);
    setRefresh(!refresh);
    setSelectedSkills([]);
  };

  const onFinishFailed = () => {
    message.error(t('trainees.message.error'));
  };

  const drawerVisibility = () => {
    setVisibilitySkillDrawer(!visibilitySkillDrawer);
  };

  useEffect(() => {
    (async () => {
      await getSkills();
    })();
  }, []);

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        setVisibilityContributorDrawer();
      }}
      width="30%"
    >
      <Col style={{ width: '100%' }}>
        <Title level={3}>{t(`contributors.form.title.create`)}</Title>
      </Col>
      <Form
        form={form}
        layout="vertical"
        name="test"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        style={{ width: '100%' }}
      >
        {fields.map((field) =>
          generateFields('contributors' || 'contributors', field)
        )}
        <Form.Item label={t('skills.title')}>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            mode="multiple"
            onSelect={onSelectSkill}
            onDeselect={onDeselect}
            value={selectedSkills}
            allowClear
            options={skills.map((skill) => ({
              label: skill.name,
              value: skill._id
            }))}
            loading={isFieldsLoading}
          />
          <Row
            style={{ margin: '10px 0 8px 0' }}
            align="middle"
            justify="space-between"
          >
            <Link to={{ pathname: '/skills' }}>
              <span>{t('contributors.form.manage_skills')}</span>
            </Link>
            <ButtonAdd onClick={drawerVisibility} />
          </Row>
        </Form.Item>
        <Form.Item
          name="skills_notes"
          label={t('contributors.form.skills_notes')}
        >
          <TextArea />
        </Form.Item>
        <Form.Item name="notes" label={t('contributors.form.notes')}>
          <TextArea />
        </Form.Item>
        <Button
          type="add"
          htmlType="submit"
          style={{
            margin: '16px 0 10px 10px',
            float: 'right'
          }}
        >
          {t('buttons.save')}
          <CheckOutlined />
        </Button>
        <Button
          onClick={cancelButton}
          style={{
            float: 'right',
            margin: '16px 0 10px 0'
          }}
          type="link"
          danger
        >
          {`${t('buttons.cancel')}`}
          <CloseOutlined />
        </Button>
      </Form>
    </Drawer>
  );
};

NewContributorDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisibilityContributorDrawer: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired
};

export default NewContributorDrawer;
